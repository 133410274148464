import React from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmationModal = ({
  show,
  toggle,
  message,
  onConfirm,
  confirmLabel,
  declineLabel,
}) => {
  return (
    <Modal isOpen={show} toggle={toggle}>
      <ModalHeader className="m-0 border-bottom-0" toggle={toggle} />
      <ModalBody className="py-0 my-0">{message}</ModalBody>
      <ModalFooter className="border-top-0">
        <div className="d-flex flex-row">
          <button
            className="btn btn-secondary btn-sm mr-2 px-4"
            onClick={() => onConfirm(false)}
          >
            {declineLabel}
          </button>
          <button className="btn btn-primary btn-sm px-4" onClick={() => onConfirm(true)}>
            {confirmLabel}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

ConfirmationModal.defaultProps = {
  confirmLabel: 'Yes',
  declineLabel: 'No',
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmationModal;
