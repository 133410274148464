import Patients from './Views/Patients';
import Messaging from './Views/Messaging';
import Privacy from './Views/Privacy';
import Help from './Views/Help';
import PatientSignUp from './Views/PatientSignUp';
import PatientPortal from './Views/PatientPortal';
import Roles from './Config/Roles';

export const PrivateRoutes = [
  {
    title: 'Messaging',
    path: '/',
    component: Messaging,
    exact: true,
    // permission: [Roles.PRACTITIONER],
  },
  {
    title: 'Patients',
    path: '/patients',
    component: Patients,
    exact: true,
    // permission: [Roles.PRACTITIONER],
  },
  {
    title: 'Privacy',
    path: '/clinic/privacy',
    component: Privacy,
    exact: true,
    // permission: [Roles.PRACTITIONER],
  },
  {
    title: 'Help',
    path: '/clinic/help',
    component: Help,
    exact: true,
    // permission: [Roles.PRACTITIONER],
  },
  {
    title: 'Patient Portal',
    path: '/patient',
    component: PatientPortal,
    exact: true,
    // permission: [Roles.PATIENT],
  },
];

export const PublicRoutes = [
  {
    title: 'Privacy',
    path: '/privacy',
    component: Privacy,
    exact: true,
  },
  {
    title: 'Help',
    path: '/help',
    component: Help,
    exact: true,
  },
  {
    title: 'Patient Sign Up',
    path: '/patient-signup',
    component: PatientSignUp,
    exact: true,
  },
];
