import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import moment from 'moment';
import parsePhoneNumber from 'libphonenumber-js';

import FormInput from '../../Blocks/FormInput';

import BuildingIcon from '../../Assets/building.svg';
import CalendarIcon from '../../Assets/calendar.svg';
import GenderIcon from '../../Assets/gender.svg';
import EmailIcon from '../../Assets/envelope.svg';
import LocationIcon from '../../Assets/geo-alt.svg';
import PersonIcon from '../../Assets/person.svg';
import PhoneIcon from '../../Assets/phone.svg';
import PhoneLockIcon from '../../Assets/phone-lock.svg';
import ShieldIcon from '../../Assets/shield.svg';
import IdCardIcon from '../../Assets/id-card.svg';

import { createPatient } from '../../Services/adminModuleService';
import { signUpConfirmation } from '../../Services/backendService';
import FormSelect from '../../Blocks/FormSelect';

function PatientCreateModal({ show, toggle, onPatientCreate }) {
  const [isLoading, setIsLoading] = useState(false);
  const [formIsValid, setIsFormValid] = useState(false);
  const [patient, setPatient] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [fieldValidity, setFieldValidity] = useState({
    email: true,
    address2: true,
    nhifCapitation: true,
    patientId: true,
  });

  const fields = [
    'firstName',
    'lastName',
    'phoneNumber',
    'gender',
    'birthDate',
    'email',
    'address1',
    'address2',
    'town',
    'city',
    'nhifCapitation',
    'patientId',
    'otp',
  ];

  const onValueChange = ({ name, value, valid }) => {
    if (valid) {
      const updatedFieldValidity = { ...fieldValidity, [name]: true };
      setIsFormValid(fields.every(f => !!updatedFieldValidity[f]));
      setPatient({ ...patient, [name]: value });
      setFieldValidity(updatedFieldValidity);
      setFormErrors({ ...formErrors, [name]: false });
    } else {
      setIsFormValid(false);
      setFieldValidity({ ...fieldValidity, [name]: false });
    }
  };

  const textValidator = val => val && val.trim().length > 0;

  const phoneNumberValidator = val => {
    const parsed = parsePhoneNumber(val, 'KE');
    return parsed && parsed.isValid();
  };

  const dateValidator = val => {
    if (val && /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(val.trim())) {
      return moment(val.trim()).isValid();
    }
    return false;
  };

  const onSubmit = evt => {
    evt.preventDefault();
    const organizationId = sessionStorage.getItem('organization_id');

    const payload = {
      organizationId,
      name: {
        firstName: patient.firstName,
        lastName: patient.lastName,
      },
      birthDate: patient.birthDate
        ? moment(patient.birthDate.trim()).format('DD/MM/YYYY')
        : null,
      gender: patient.gender || 'UNKNOWN',
      phoneNumber: [patient.phoneNumber],
      address: {
        text: patient.address1,
        line: patient.address2 ? [patient.address2] : [],
        town: patient.town || null,
        city: patient.city || null,
      },
      emailAddress: patient.email || null,
      nhifCapitationNumber: patient.nhifCapitation || null,
      externalId: patient.patientId || null,
      active: false,
    };

    createPatient(payload)
      .then(createdPatient => {
        setIsLoading(true);
        signUpConfirmation({
          otp: patient.otp,
          patientId: createdPatient.id,
        }).then(() => {
          onPatientCreate(createdPatient);
          setIsLoading(false);
          setPatient({});
          setFieldValidity({
            email: true,
            address2: true,
            nhifCapitation: true,
            patientId: true,
          });
          toggle();
        });
      })
      .catch(err => {
        setIsLoading(false);
        if (err.response) {
          const errors = {};
          (err.response.data.errors || []).forEach(error => {
            errors[error.field] = true;
          });
          setFormErrors(errors);
          setIsFormValid(false);
        }
      });
  };

  return (
    <Modal isOpen={show} toggle={toggle} className="modal-form">
      <ModalHeader toggle={toggle} className="border-bottom-0 mb-0 pb-0" />
      <ModalBody>
        <form className="py-2">
          <div className="w-100 p-4 d-flex flex-column">
            <h4 className="">New Patient</h4>
            <div className="w-100 d-flex flex-md-row flex-sm-column flex-wrap">
              <div className="col-md-6 col-sm-12 px-md-2 py-1">
                <FormInput
                  name="firstName"
                  label="First Name"
                  icon={PersonIcon}
                  onValueChange={onValueChange}
                  validator={textValidator}
                />
              </div>
              <div className="col-md-6 col-sm-12 px-md-2 py-1">
                <FormInput
                  name="lastName"
                  label="Last Name"
                  icon={PersonIcon}
                  onValueChange={onValueChange}
                  validator={textValidator}
                />
              </div>
              <div className="col-md-6 col-sm-12 px-md-2 py-1">
                <FormSelect
                  name="gender"
                  label="Gender"
                  type="text"
                  icon={GenderIcon}
                  onValueChange={onValueChange}
                  options={[
                    {
                      value: 'MALE',
                      label: 'Male',
                    },
                    {
                      value: 'FEMALE',
                      label: 'Female',
                    },
                    {
                      value: 'OTHER',
                      label: 'Prefer not to say',
                    },
                  ]}
                />
              </div>
              <div className="col-md-6 col-sm-12 px-md-2 py-1">
                <FormInput
                  name="birthDate"
                  label="Date of Birth (DD/MM/YYYY)"
                  type="text"
                  icon={CalendarIcon}
                  onValueChange={onValueChange}
                  validator={dateValidator}
                />
              </div>
              <div className="col-md-6 col-sm-12 px-md-2 py-1">
                <FormInput
                  name="phoneNumber"
                  label="Phone Number"
                  type="tel"
                  onValueChange={onValueChange}
                  validator={phoneNumberValidator}
                  icon={PhoneIcon}
                />
                {formErrors.phoneNumber && (
                  <span className="validation-error-text mt-0 pt-0 text-center">
                    Please enter a valid phone number.
                  </span>
                )}
              </div>
              <div className="col-md-6 col-sm-12 px-md-2 py-1">
                <FormInput
                  name="email"
                  label="Email Address"
                  type="email"
                  icon={EmailIcon}
                  onValueChange={onValueChange}
                  isRequired={false}
                />
              </div>
            </div>

            <h4 className="mt-2">Address</h4>
            <div className="w-100 d-flex flex-md-row flex-sm-column flex-wrap">
              <div className="col-md-6 col-sm-12 px-md-2 py-1">
                <FormInput
                  name="address1"
                  label="Address Line 1"
                  icon={LocationIcon}
                  onValueChange={onValueChange}
                  validator={textValidator}
                />
              </div>
              <div className="col-md-6 col-sm-12 px-md-2 py-1">
                <FormInput
                  name="address2"
                  label="Address Line 2"
                  icon={LocationIcon}
                  onValueChange={onValueChange}
                  isRequired={false}
                />
              </div>
              <div className="col-md-6 col-sm-12 px-md-2 py-1">
                <FormInput
                  name="town"
                  label="Town/Neighbourhood"
                  icon={BuildingIcon}
                  onValueChange={onValueChange}
                  validator={textValidator}
                />
              </div>
              <div className="col-md-6 col-sm-12 px-md-2 py-1">
                <FormInput
                  name="city"
                  label="City"
                  icon={BuildingIcon}
                  onValueChange={onValueChange}
                  validator={textValidator}
                />
              </div>
            </div>

            <h4 className="mt-2">Other</h4>
            <div className="w-100 d-flex flex-md-row flex-sm-column flex-wrap">
              <div className="col-md-6 col-sm-12 px-md-2 py-1">
                <FormInput
                  name="nhifCapitation"
                  label="NHIF Capitation Number"
                  icon={ShieldIcon}
                  onValueChange={onValueChange}
                  isRequired={false}
                />
              </div>
              <div className="col-md-6 col-sm-12 px-md-2 py-1">
                <FormInput
                  name="patientId"
                  label="Patient ID (from your existing system)"
                  icon={IdCardIcon}
                  onValueChange={onValueChange}
                  isRequired={false}
                />
              </div>
            </div>

            <h4 className="mt-2">Signup</h4>
            <h5>Please read this to the patient:</h5>
            <p>
              By signing up for this app, you agree to have Ilara health collect your
              medical data. Ilara will never disclose this data to any third parties,
              except in aggregate. Ilara is in compliance with international data security
              and privacy standards.
            </p>
            <p>
              The patient will receive an SMS with the above message and a One Time
              Password (OTP). By entering the OTP below, they agree to the above terms and
              conditions.
            </p>

            <div className="d-flex flex-md-row flex-column">
              <div className="col-md-6 col-12 px-md-2 py-1">
                <FormInput
                  name="otp"
                  label="One Time Password"
                  type="number"
                  onValueChange={onValueChange}
                  icon={PhoneLockIcon}
                />
              </div>
              <button
                className="button-reset block form-button__submit col-md-6 col-12 px-md-2 py-1"
                type="submit"
                onClick={onSubmit}
                disabled={!formIsValid || isLoading}
              >
                {isLoading ? <Spinner className="m-auto" /> : 'Signup'}
              </button>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}

PatientCreateModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onPatientCreate: PropTypes.func.isRequired,
};

export default PatientCreateModal;
