import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import TimeUtil from '../../Utils/Time';

const ChatBox = forwardRef(({ patient, practitioners, messages }, ref) => {
  const activePractitioner = sessionStorage.getItem('practitioner_id');

  const messagesEndRef = useRef(null);

  useImperativeHandle(ref, () => ({
    scrollToBottom() {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    },
  }));

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [messages]);

  const getDisplayName = practitionerId => {
    const practitioner = practitioners.find(p => p.id === practitionerId);
    if (practitioner) {
      return `${practitioner.name.prefix} ${practitioner.name.firstName} ${practitioner.name.lastName}`;
    }
  };

  const formatChannel = channel => {
    if (channel === 'whatsapp') return 'WhatsApp';
    if (channel === 'sms') return 'SMS';
  };

  const getMessage = msgObj => {
    if (msgObj && msgObj.content && msgObj.content.message) {
      return (
        <p style={{ whiteSpace: 'pre-line' }}>
          {msgObj.content.message.replace(/\\n/g, '\n')}
        </p>
      );
    }
  };

  return (
    <div className="consultation-chatbox d-flex flex-column flex-grow-1 px-4">
      {messages.map((message, id) => {
        if (message.from === activePractitioner) {
          return (
            <div className={'row message_float_right'} key={id}>
              <div
                key={id}
                message={message.content.message}
                date={TimeUtil.displayTime(message.sent_ts)}
                className={`message practitioner ${
                  message.sent_status === 'queued_practitioner_message' ? 'queued' : ''
                }`}
              >
                <div className="chat-name">
                  <p>{getDisplayName(message.from)}</p>
                  <hr className="chat-hr" />
                </div>
                {getMessage(message)}{' '}
                <span className={'message-time text-right'}>
                  {TimeUtil.displayTime(message.sent_ts)}
                  {message.broadcast_id && ' (SMS Broadcast)'}
                </span>{' '}
              </div>
              <div className="clearfix"></div>
            </div>
          );
        } else if (message.from === 'mbali_health') {
          return (
            <div className={'row message_float_center'} key={id}>
              <div
                key={id}
                className={'message server'}
                message={message.content.message}
                date={TimeUtil.displayTime(message.sent_ts)}
              >
                {message.to.startsWith('Patient') && <p>DiffCov Health Tip:</p>}
                <p>{getMessage(message)}</p>
                <span className={'message-time text-muted text-center-flex'}>
                  {TimeUtil.displayTime(message.sent_ts)}
                </span>
              </div>
              <div className="clearfix"></div>
            </div>
          );
        } else {
          if (message.from.startsWith('Patient')) {
            return (
              <div className={'row message_float_left'} key={id}>
                <div
                  key={id}
                  className={'message patient'}
                  message={message.content.message}
                  date={TimeUtil.displayTime(message.sent_ts)}
                >
                  <div className="chat-name">
                    <p>
                      {patient.name.firstName} {patient.name.lastName}
                    </p>
                    <hr className="chat-hr" />
                  </div>
                  {getMessage(message)}{' '}
                  <span className={'message-time text-muted text-right'}>
                    {TimeUtil.displayTime(message.sent_ts)}{' '}
                    {message.channel && `via ${formatChannel(message.channel)}`}
                  </span>{' '}
                </div>
                <div className="clearfix"></div>
              </div>
            );
          } else {
            return (
              <div className={'row message_float_left'} key={id}>
                <div
                  key={id}
                  className={'message multiple_practitioner'}
                  message={message.content.message}
                  date={TimeUtil.displayTime(message.sent_ts)}
                >
                  <div className="chat-name">
                    <p>{getDisplayName(message.from)}</p>
                    <hr className="chat-hr" />
                  </div>
                  {getMessage(message)}{' '}
                  <span className={'message-time text-muted text-right'}>
                    {TimeUtil.displayTime(message.sent_ts)}
                    {message.broadcast_id && ' (SMS Broadcast)'}
                  </span>{' '}
                </div>
                <div className="clearfix"></div>
              </div>
            );
          }
        }
      })}
      <div ref={messagesEndRef}></div>
    </div>
  );
});

ChatBox.defaultProps = {
  practitioners: [],
  messages: [],
};

ChatBox.propTypes = {
  patient: PropTypes.shape({
    name: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
  }),
  practitioners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.shape({
        prefix: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    })
  ).isRequired,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.shape({
        message: PropTypes.string,
      }).isRequired,
      sent_ts: PropTypes.string,
    })
  ),
};

export default ChatBox;
