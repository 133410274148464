import dotenv from 'dotenv';
dotenv.config();

export const PRACTIONER_ID = 'Practitioner/4';
export const ORGANIZATION_ID = 'Organization/1';
export const PATIENT_ID = 'aa8df3efb618f5141202a191';
export const CONSULTATION_ID = '5ea4cb0df8c3d30028614a74';
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const ADMIN_MODULE_URL = process.env.REACT_APP_ADMIN_MODULE_URL;
export const VAPID_PUBLIC_KEY = process.env.REACT_APP_PUBLIC_VAPID_KEY;
export const HOURS = 1000 * 60 * 60;
export const MINUTES = 1000 * 60;
export const SECONDS = 1000;
export const DEFAULT_PAYMENT_VALUE = 100;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const KEYCLOAK_CONFIG_FILE = process.env.REACT_APP_KEYCLOAK_CONFIG_FILE;

export const PaymentLabel = {
  CONSULTATION: 'consultation',
  LAB_TEST: 'lab_test',
  MEDICATION: 'medication',
};

export const VoiceOrganizations = [
  'Organization/1',
  'Organization/6602',
  'Organization/6958',
  'Organization/6959',
  'Organization/6649',
  'Organization/6651',
  'Organization/6654',
  'Organization/6653',
  'Organization/6655',
  'Organization/3378',
  'Organization/4',
  'Organization/3397',
];
