import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

import tooltip from '../../Assets/info.svg';
import { HOURS, SECONDS, MINUTES } from '../../Constants';

const ConsultationTimer = ({ lastPatientMessage, consultationStatus }) => {
  const CountDownDate = new Date(lastPatientMessage.sent_ts);
  CountDownDate.setDate(CountDownDate.getDate() + 1);

  const calculateTimeLeft = () => {
    let difference = CountDownDate - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / HOURS) % 24),
        minutes: Math.floor((difference / MINUTES) % 60),
        seconds: Math.floor((difference / SECONDS) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach(interval => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval} {'Left'}
      </span>
    );
  });

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div className="consultation-timer p-1">
      {timerComponents.length && consultationStatus !== 'closed' ? (
        timerComponents[0]
      ) : (
        <span>Chat Closed</span>
      )}

      <div>
        <img src={tooltip} id="infoToolTip" alt="info" className="infoToolTip" />
        <Tooltip
          placement="right"
          isOpen={tooltipOpen}
          target="infoToolTip"
          toggle={toggle}
        >
          Consultations are automatically closed 24hrs after the last message from the
          patient.
          <br />
          If a new message is sent then the timer restarts. <br />
          You can restart a consultation by sending a request to the patient. <br />
        </Tooltip>
      </div>
    </div>
  );
};

ConsultationTimer.propTypes = {
  consultationStatus: PropTypes.string.isRequired,
  lastPatientMessage: PropTypes.shape({
    sent_ts: PropTypes.string.isRequired,
  }).isRequired,
};

export default ConsultationTimer;
