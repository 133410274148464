import React from 'react';
import moment from 'moment';
import { Table } from 'reactstrap';
import classnames from 'classnames';

const DiabetesTable = ({ view, hba1cReadings }) => {
  return (
    <div
      className={classnames('px-4 py-5', {
        'table-container-practitioner w-100': view === 'practitioner',
        'table-container-patient w-75': view === 'patient',
      })}
    >
      <p style={{ textAlign: 'center', color: '#ffffff', fontWeight: 700 }}>
        {' '}
        Filterable List of HBA1C Readings{' '}
      </p>
      <Table borderless>
        <thead>
          <tr class="d-flex justify-content-between" style={{ color: '#ffffff' }}>
            <th>Date</th>
            <th>HBA1C Reading</th>
          </tr>
        </thead>
        <tbody>
          {hba1cReadings ? (
            hba1cReadings.map(hba1cReading => (
              <tr
                class="d-flex justify-content-between row-height"
                style={{ color: '#ffffff' }}
              >
                <td>{moment(hba1cReading.testTime).format('DD/MM/YYYY')}</td>
                <td>{hba1cReading.result}%</td>
              </tr>
            ))
          ) : (
            <tr
              class="d-flex justify-content-between row-height"
              style={{ color: '#ffffff' }}
            >
              no readings available
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default DiabetesTable;
