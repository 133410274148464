import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { isEqual } from 'lodash';
import { PrivateRoutes } from '../routes';
import { getAllowedRoutes } from '../Utils/AllowedRoutes';

import { AppContext } from '../AppContext';
import VoiceCall from '../Compositions/Voice/VoiceCall';
import KeycloakService from '../Services/KeycloakService';

const Loading = (
  <div className="spinner-div">
    <Spinner className="loading-spinner" style={{ width: '3rem', height: '3rem' }} />
  </div>
);

const PrivateRoute = ({ component: Component, path, permission, ...rest }) => {
  const { userIsAuthenticated, logoutRef, setUserIsAuthenticated } =
    useContext(AppContext);

  logoutRef.current = useCallback(() => {
    sessionStorage.clear();
    KeycloakService.doLogout();
    setUserIsAuthenticated(false);
  }, [setUserIsAuthenticated]);

  useEffect(() => {
    let interval;
    async function loadAuth() {
      interval = await KeycloakService.initKeycloak(setUserIsAuthenticated);
    }

    loadAuth().then();

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  if (!userIsAuthenticated) {
    return null;
  }

  return (
    <>
      <Route
        {...rest}
        element={
          <>
            {
              <React.Suspense fallback={<Loading />}>
                <Component {...rest} />
              </React.Suspense>
            }
          </>
        }
      />
      <VoiceCall />
    </>
  );
};

export default PrivateRoute;
