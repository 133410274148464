import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { DEFAULT_PAYMENT_VALUE, PaymentLabel } from '../../Constants';

function PaymentModal(props) {
  const [amount, setPaymentAmount] = useState(DEFAULT_PAYMENT_VALUE);
  const [label, setPaymentLabel] = useState(PaymentLabel.CONSULTATION);
  const [description, setPaymentDescription] = useState('');
  const [hasDescriptionError, setHasDescriptionError] = useState(false);

  const onDescriptionChange = evt => {
    if (hasDescriptionError) {
      setHasDescriptionError(false);
    }
    setPaymentDescription(evt.target.value);
  };

  const onLabelChange = evt => {
    setHasDescriptionError(false);
    setPaymentLabel(evt.target.value);
  };

  const requestPayment = () => {
    if (!description && label !== PaymentLabel.CONSULTATION) {
      setHasDescriptionError(true);
    } else {
      props.requestPayment({
        consultationId: props.consultationId,
        amount,
        label,
        description,
      });
    }
  };

  return (
    <>
      <Modal isOpen={props.show} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>Request Payment</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="payment_amount">Payment Amount</Label>
              <Input
                type="number"
                name="payment_amount"
                id="payment_amount"
                value={amount}
                onChange={e => setPaymentAmount(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="payment_label">Payment Label</Label>
              <Input
                type="select"
                name="payment_label"
                id="payment_label"
                value={label}
                onChange={onLabelChange}
              >
                <option value={PaymentLabel.CONSULTATION}>Consultation</option>
                <option value={PaymentLabel.LAB_TEST}>Lab Test</option>
                <option value={PaymentLabel.MEDICATION}>Medication</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="payment_description">Payment Description</Label>
              <Input
                className={hasDescriptionError && 'validation-error-input'}
                type="text"
                name="payment_description"
                id="payment_description"
                value={description}
                onChange={onDescriptionChange}
              />
              {hasDescriptionError && (
                <p className="validation-error-text">Description is required</p>
              )}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={requestPayment}>
            {' '}
            Request Payment{' '}
          </Button>{' '}
          <Button color="secondary" onClick={() => props.toggle()}>
            {' '}
            Cancel{' '}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default PaymentModal;
