import React, { Component, createRef } from 'react';
import MediaQuery from 'react-responsive';
import { Nav, NavItem, NavLink, Row, Col, Spinner } from 'reactstrap';
import classnames from 'classnames';
import history from 'history/browser';

import Layout from '../Blocks/Layout';
import { AppContext } from '../AppContext';
import SingleMessageEntry from '../Compositions/Messages/SingleMessageEntry';
import BroadcastMessageEntry from '../Compositions/Messages/BroadcastMessageEntry';
import ChatView from '../Compositions/Messages/ChatView';
import PatientsModal from '../Compositions/Messages/PatientsModal';
import {
  getConsultationMessages,
  getOpenConsultationsForPatient,
  requestPayment,
  restartConsultation,
  startConsultation,
} from '../Services/backendService';
import {
  getOrganizationPatients,
  getOrganizationPractitioners,
  getPatientDetails,
} from '../Services/adminModuleService';
import UserService from '../Services/KeycloakService';
import WebSocket from '../Utils/WebSocket';

import MessageIcon from '../Assets/message.svg';

/**
 * @typedef OrganizationMessages
 * @property {object}  conversation - set if a conversation
 * @property {object} broadcast - set if a broadcast message
 * @property {Array<object>} recipients - list of patients objects (only set for broadcast messages)
 * @property {object} patient - patient details (only set for non-broadcast messages)
 * @property {object} preview - most recent message in a conversation
 */
class Messaging extends Component {
  state = {
    activeTab: 'messages',
    activeCount: 0,
    closedCount: 0,
    allCount: 0,
    activePatient: null,
    openConsultationForActivePatient: null,
    isConsultation: false,
    activeConsultation: null,
    activeBroadcast: null,
    activeConversation: null,
    consultationsViewStatus: 'active',
    isMessagesLoading: true,
    isChatLoading: true,
    showChatView: false,
    isBroadcastView: false,
    broadcastRecipients: [],
    /** @type {OrganizationMessages} */
    organizationMessages: {},
    consultationMessages: {},
    patients: {},
    showPatientsModal: false,
    filteredConsultationMessages: [],
    messages: [],
    practitioners: [],
    queriedConsultation: null,
    queriedConversation: null,
    queriedBroadcast: null,
  };

  socketRef = createRef(null);
  static contextType = AppContext;

  async componentDidMount() {
    if (history.location.search) {
      const params = new URLSearchParams(history.location.search);
      this.setState({
        queriedConsultation: params.get('consultationId'),
        queriedConversation: params.get('conversationId'),
        queriedBroadcast: params.get('broadcastId'),
      });
    }

    await this.fetchPatientsAndPractitioners();
  }

  async fetchPatientsAndPractitioners() {
    const info = await UserService.fetchAuthInfo();

    if (!info) {
      return;
    }

    const patients = await getOrganizationPatients(info.organization.id);
    const patientList = patients || [];
    patientList.sort((a, b) => {
      if (a.name.firstName < b.name.firstName) {
        return -1;
      }
      if (a.name.firstName > b.name.firstName) {
        return 1;
      }
      return a.name.lastName < b.name.lastName ? -1 : 1;
    });

    const patientsMap = {};
    patientList.forEach(patient => {
      patientsMap[patient.id] = patient;
    });

    this.setState({ patients: patientsMap }, () => {
      this.setupSocketConnection();
    });

    const practitioners = await getOrganizationPractitioners(info.organization.name);
    this.setState({ practitioners });
  }

  onOrganizationMessages = () => {
    WebSocket.onOrganizationMessages(
      this.socketRef.current,
      ({ conversations, broadcasts }) => {
        /** @type {OrganizationMessages} */
        const organizationMessages = {};

        const { queriedConversation, queriedBroadcast } = this.state;

        let activeConsultationId = null;
        let activePatientId = null;
        let activeBroadcastId = null;

        conversations.forEach(conversation => {
          const conversationPatient = this.state.patients[conversation.patient_id];
          if (conversationPatient) {
            organizationMessages[conversation._id] = {
              patient: conversationPatient,
              preview: conversation.last_message,
              conversation,
            };
          }

          if (queriedConversation && queriedConversation === conversation._id) {
            activeConsultationId = conversation._id;
            activePatientId = this.state.patients[conversation.patient_id].id;
          }
        });

        broadcasts.forEach(broadcast => {
          const recipients = broadcast.recipients.map(
            p => this.state.patients[p.patient_id]
          );
          organizationMessages[broadcast._id] = {
            recipients,
            broadcast,
            preview: broadcast.message,
          };

          if (queriedBroadcast && queriedBroadcast === broadcast._id) {
            activeConsultationId = null;
            activeBroadcastId = broadcast._id;
            activePatientId = null;
          }
        });

        // We make a call to `onMessageSelect` in the callback to ensure conversations
        // and broadcasts have been loaded
        this.setState(
          {
            organizationMessages,
            isMessagesLoading: false,
          },
          () => {
            if (queriedConversation || queriedBroadcast) {
              this.onMessageSelect({
                conversationId: activeConsultationId,
                patientId: activePatientId,
                broadcastId: activeBroadcastId,
              });
            }
          }
        );
      }
    );
  };

  onPatientOrganizationMessages = () => {
    WebSocket.onPatientOrganizationMessages(this.socketRef.current, messages => {
      this.setState({
        messages: messages.sort((a, b) => (a.sent_ts > b.sent_ts ? 1 : -1)),
        showChatView: true,
        isChatLoading: false,
      });
    });
  };

  onOrganizationMessage = () => {
    WebSocket.onOrganizationMessage(
      this.socketRef.current,
      ({ message, conversation }) => {
        const orgMsgs = { ...this.state.organizationMessages };

        // Delete temporary conversation
        delete orgMsgs['temp_conversation_id'];

        const patient = this.state.patients[conversation.patient_id];
        if (patient) {
          orgMsgs[conversation._id] = {
            patient,
            preview: message,
            conversation,
          };

          let messages = this.state.messages.filter(m => !m.sending);
          if (
            this.state.activePatient &&
            this.state.activePatient.id === conversation.patient_id &&
            this.state.activeTab === 'messages'
          ) {
            messages.push(message);
          }

          this.setState({ organizationMessages: orgMsgs, messages });
        } else {
          // Message from a newly created patient not in the patient list
          getPatientDetails(conversation.patient_id).then(patient => {
            orgMsgs[conversation._id] = {
              patient,
              preview: message,
              conversation,
            };
            const patients = { ...this.state.patients, [patient.id]: patient };

            this.setState({ organizationMessages: orgMsgs, patients });
          });
        }
      }
    );
  };

  onBroadcastMessage = () => {
    WebSocket.onBroadcastMessage(
      this.socketRef.current,
      ({ broadcast, message, conversations }) => {
        const orgMsgs = { ...this.state.organizationMessages };

        // Delete temporary broadcast message
        delete orgMsgs['temp_broadcast_id'];

        orgMsgs[broadcast._id] = {
          preview: message,
          broadcast: broadcast,
          recipients: broadcast.recipients.map(p => this.state.patients[p.patient_id]),
        };

        // Updating existing conversations with the new broadcast message
        conversations.forEach(conversation => {
          orgMsgs[conversation._id] = {
            patient: this.state.patients[conversation.patient_id],
            preview: message,
            conversation,
          };
        });

        let activeBroadcast = null;
        let messages = this.state.messages;
        if (
          this.state.activeBroadcast &&
          (this.state.activeBroadcast._id === 'temp_broadcast_id' ||
            this.state.activeBroadcast._id === broadcast._id)
        ) {
          activeBroadcast = {
            ...broadcast,
            recipients: orgMsgs[broadcast._id].recipients,
          };
          messages = [message];
        }
        this.setState({
          organizationMessages: orgMsgs,
          activeBroadcast,
          messages,
        });
      }
    );
  };

  onConsultations = () => {
    WebSocket.onConsultations(
      this.socketRef.current,
      ({ consultations, messages, patients }) => {
        const patientsMap = {};
        patients.forEach(patient => {
          if (patient && patient.id) {
            patientsMap[patient.id] = patient;
          }
        });

        const consultationMessages = {};
        consultations.forEach(c => {
          const consultationPatient = patientsMap[c.patient_id];
          if (consultationPatient) {
            consultationMessages[c._id] = {
              consultation: c,
              patient: consultationPatient,
              messages: [],
            };
          }
        });

        messages.forEach(msg => {
          const consultation = consultationMessages[msg.consultation_id];
          if (consultation) consultation.messages.push(msg);
        });

        for (const key in consultationMessages) {
          consultationMessages[key].preview = consultationMessages[key].messages[0];
        }

        let status = this.state.consultationsViewStatus;
        let activeTab = this.state.activeTab;

        if (this.state.queriedConsultation) {
          activeTab = 'consultations';
          status =
            consultationMessages[this.state.queriedConsultation].consultation.status;
        }

        let filteredConsultationMessages = Object.values(consultationMessages);

        if (status === 'pending_patient_approval') {
          status = 'all';
        } else {
          filteredConsultationMessages = filteredConsultationMessages.filter(
            entry => entry.consultation.status === status
          );
        }

        this.setState(
          {
            activeTab,
            consultationMessages,
            filteredConsultationMessages,
            consultationsViewStatus: status,
          },
          () => {
            if (this.state.queriedConsultation) {
              this.onMessageSelect({ consultationId: this.state.queriedConsultation });
            }
          }
        );
        this.setConsultationStatusCounts(consultationMessages);
      }
    );
  };

  onConsultationMessages = () => {
    WebSocket.onConsultationMessages(this.socketRef.current, messages => {
      this.setState({
        messages: messages.reverse(),
        isChatLoading: false,
        showChatView: true,
      });
    });
  };

  onConsultationMessage = () => {
    WebSocket.onConsultationUpdate(
      this.socketRef.current,
      ({ consultation, patient, message }) => {
        const updatedConsultations = { ...this.state.consultationMessages };
        updatedConsultations[consultation._id] = {
          consultation,
          patient,
          messages: [message],
          preview: message,
        };

        const updatedChatMessages = this.state.messages.filter(m => !m.sending);
        let activeConsultation = this.state.activeConsultation;
        let consultationsViewStatus = this.state.consultationsViewStatus;

        if (activeConsultation && activeConsultation._id === consultation._id) {
          activeConsultation = consultation;
          consultationsViewStatus = consultation.status;
          updatedChatMessages.push(message);
        }

        let filteredList = [];
        if (consultationsViewStatus === 'all') {
          filteredList = Object.values(updatedConsultations);
        } else {
          filteredList = Object.values(updatedConsultations).filter(
            entry => entry.consultation.status === consultationsViewStatus
          );
        }

        const [activeCount, closedCount, allCount] =
          this.getConsultationStatusCounts(updatedConsultations);

        this.setState({
          activeConsultation,
          consultationsViewStatus,
          allCount,
          closedCount,
          activeCount,
          consultationMessages: updatedConsultations,
          filteredConsultationMessages: filteredList,
          messages: updatedChatMessages,
        });
      }
    );
  };

  onConsultationStart = () => {
    WebSocket.onConsultationStart(this.socketRef.current, ({ consultation }) => {
      if (
        this.state.activePatient &&
        this.state.activePatient.id === consultation.patient_id
      ) {
        this.setState({
          openConsultationForActivePatient: consultation,
        });
      }
    });
  };

  onConsultationEnd = () => {
    WebSocket.onConsultationEnd(this.socketRef.current, ({ patientId }) => {
      if (this.state.activePatient && this.state.activePatient.id === patientId) {
        this.setState({
          openConsultationForActivePatient: null,
        });
      }
    });
  };

  setupSocketConnection() {
    this.socketRef.current = WebSocket.getSocket();

    WebSocket.addOnDisconnectListener(this.socketRef.current, data => {
      console.log('Connection disconnected', data);
      this.socketRef.current = WebSocket.getSocket();
    });

    WebSocket.addOnErrorListener(this.socketRef.current, data => {
      console.log('Connection error', data);
      this.setupSocketConnection();
    });

    WebSocket.addOnConnectListener(this.socketRef.current, () => {
      console.log('Client is connected.');
      this.onConsultations();
      this.onOrganizationMessages();
      this.onOrganizationMessage();
      this.onBroadcastMessage();
      this.onConsultationMessages();
      this.onConsultationMessage();
      this.onConsultationStart();
      this.onConsultationEnd();
      this.onPatientOrganizationMessages();
    });
  }

  getConsultationStatusCounts(consultations) {
    let activeCount = 0;
    let closedCount = 0;
    let allCount = 0;
    Object.values(consultations).forEach(entry => {
      if (entry.consultation.status === 'active') {
        activeCount += 1;
      } else if (entry.consultation.status === 'closed') {
        closedCount += 1;
      }
      allCount += 1;
    });
    return [activeCount, closedCount, allCount];
  }

  setConsultationStatusCounts(consultations) {
    const [activeCount, closedCount, allCount] =
      this.getConsultationStatusCounts(consultations);
    this.setState({ activeCount, closedCount, allCount });
  }

  toggleTab(tab) {
    if (tab === 'consultations' && !this.state.consultationMessages) {
      this.setState({
        activeTab: tab,
        activeConsultation: null,
        activePatient: null,
        showChatView: false,
        isMessagesLoading: true,
      });
    } else {
      this.setState({
        activeTab: tab,
        activeConsultation: null,
        activePatient: null,
        showChatView: false,
      });
    }
  }

  togglePatientsModal = () => {
    this.setState({ showPatientsModal: !this.state.showPatientsModal });
  };

  showMessagesList = () => {
    this.setState({ showChatView: false, activePatient: null, activeConsultation: null });
  };

  selectConsultationStatus = evt => {
    const status = evt.target.value;
    if (status === this.state.consultationsViewStatus) {
      return; // status unchanged, do nothing
    }

    if (status === 'all') {
      this.setState({
        consultationsViewStatus: status,
        filteredConsultationMessages: Object.values(this.state.consultationMessages),
      });
    } else {
      const filteredConsultationMessages = Object.values(
        this.state.consultationMessages
      ).filter(entry => entry.consultation.status === status);
      this.setState({
        filteredConsultationMessages,
        consultationsViewStatus: status,
      });
    }
  };

  startConsultation = async consultationNotes => {
    const organizationId = sessionStorage.getItem('organization_id');
    const practitionerId = sessionStorage.getItem('practitioner_id');
    const payload = {
      notes: consultationNotes,
      patientId: this.state.activePatient.id,
      organizationId,
      practitionerId,
    };
    try {
      const consultation = await startConsultation(payload);
      const updatedConsultations = { ...this.state.consultationMessages };

      updatedConsultations[consultation._id] = {
        consultation,
        patient: this.state.activePatient,
        messages: [{ content: { message: consultationNotes }, sent_ts: Date.now() }],
      };

      const filteredList = Object.values(updatedConsultations).filter(
        entry => entry.consultation.status === 'active'
      );

      this.setState({
        consultationMessages: updatedConsultations,
        filteredConsultationMessages: filteredList,
        consultationsViewStatus: 'active',
        activeCount: this.state.activeCount + 1,
        allCount: this.state.allCount + 1,
        activeConsultation: consultation,
        isChatLoading: true,
        activeTab: 'consultations',
      });
    } catch (err) {
      if (err.response) {
        if (err.response.data.error === 'WhatsApp session has expired.') {
          this.setState({
            messages: [
              ...this.state.messages,
              {
                from: 'mbali_health',
                content: {
                  message: `Patient's WhatsApp session has expired. A request to restart the session has been sent. You can start the consultation once the patient accepts the request.`,
                },
                sent_ts: Date.now(),
              },
            ],
          });
        }
      }
      // TODO: handle other errors
    }
  };

  restartConsultation = () => {
    restartConsultation(this.state.activeConsultation._id);
  };

  goToOpenConsultation = () => {
    let status = this.state.openConsultationForActivePatient.status;
    let filteredConsultationMessages = this.state.filteredConsultationMessages;
    if (status === 'pending_patient_approval') {
      status = 'all';
      filteredConsultationMessages = Object.values(this.state.consultationMessages);
    } else {
      filteredConsultationMessages = Object.values(
        this.state.consultationMessages
      ).filter(entry => entry.consultation.status === 'active');
    }

    this.setState({
      filteredConsultationMessages,
      activeConsultation: this.state.openConsultationForActivePatient,
      isChatLoading: true,
      activeTab: 'consultations',
      consultationsViewStatus: status,
    });

    WebSocket.getConsultationMessages(this.socketRef.current, {
      consultationId: this.state.openConsultationForActivePatient._id,
      practitionerId: sessionStorage.getItem('practitioner_id'),
      organizationId: sessionStorage.getItem('organization_id'),
    });
  };

  sendMessage = message => {
    const { activeConsultation, activeConversation } = this.state;
    const consultationId = activeConsultation ? activeConsultation._id : null;
    const conversationId =
      activeConversation && activeConversation._id !== 'temp_conversation_id'
        ? activeConversation._id
        : null;
    const organizationId = sessionStorage.getItem('organization_id');
    const practitionerId = sessionStorage.getItem('practitioner_id');

    const activeBroadcast = this.state.isBroadcastView
      ? { _id: 'temp_broadcast_id' }
      : null;

    const messages = [
      ...this.state.messages,
      {
        consultation_id: consultationId,
        broadcast_id: activeBroadcast ? 'temp_broadcast_id' : null,
        from: practitionerId,
        content: {
          message,
        },
        sent_ts: Date.now(),
        sent_status: 'queued_practitioner_message',
        sending: true, // Temporary property used to update messages once processed by the backend
      },
    ];

    const recipients = this.state.broadcastRecipients.map(p => p.id);

    this.setState({ messages, activeBroadcast, recipients: [] });
    WebSocket.send(this.socketRef.current, {
      organizationId,
      practitionerId,
      consultationId,
      conversationId,
      isBroadcast: this.state.isBroadcastView,
      recipients,
      message,
      patientId: this.state.activePatient ? this.state.activePatient.id : null,
    });
  };

  requestPayment = paymentDetails => {
    requestPayment(paymentDetails);
  };

  onMessageSelect = ({ conversationId, consultationId, broadcastId, patientId }) => {
    if (this.state.activeTab === 'messages') {
      // Selecting patient currently in view should not have any effect
      if (this.state.activePatient && patientId === this.state.activePatient.id) {
        return;
      }
      // Broadcast
      if (broadcastId) {
        const { preview, broadcast, recipients } =
          this.state.organizationMessages[broadcastId];
        let messages = [preview];
        if (broadcastId === 'temp_broadcast_id') {
          messages = [];
        }
        this.setState({
          messages,
          showChatView: true,
          isChatLoading: false,
          activeBroadcast: broadcast,
          openConsultationForActivePatient: null,
          broadcastRecipients: recipients,
          isBroadcastView: true,
          activePatient: null,
          activeConversation: null,
          activeConsultation: null,
        });
        return;
      }

      // Conversation
      const conversation = this.getConversationByPatientIdOrConversationId(
        patientId,
        conversationId
      );
      const patient = this.state.patients[patientId];

      this.setState({
        isBroadcastView: false,
        activePatient: patient,
        activeBroadcast: null,
        activeConsultation: null,
        activeConversation: conversation,
        showChatView: true,
        isChatLoading: true,
      });

      getOpenConsultationsForPatient(patient.id).then(consultations => {
        let openConsultationForActivePatient = null;

        consultations.forEach(c => {
          if (c.status === 'active') {
            openConsultationForActivePatient = c;
          }
        });

        if (!openConsultationForActivePatient) {
          consultations.forEach(c => {
            if (c.status === 'pending_patient_approval') {
              openConsultationForActivePatient = c;
            }
          });
        }

        this.setState({ openConsultationForActivePatient });
      });

      // Existing conversation: fetch messages
      if (conversation && conversation._id !== 'temp_conversation_id') {
        WebSocket.getPatientOrganizationMessages(this.socketRef.current, {
          conversationId: conversation._id,
          patientId: patient.id,
          practitionerId: sessionStorage.getItem('practitioner_id'),
          organizationId: sessionStorage.getItem('organization_id'),
        });
      } else {
        // New conversation
        const tmpConversation = {
          _id: 'temp_conversation_id',
          patient_id: patient.id,
        };
        const orgMessages = { ...this.state.organizationMessages };
        orgMessages['temp_conversation_id'] = {
          conversation: tmpConversation,
          patient,
        };
        this.setState({
          activeConversation: tmpConversation,
          messages: [],
          organizationMessages: orgMessages,
          showChatView: true,
          isChatLoading: false,
        });
      }
    } else {
      // Consultations messages
      if (
        this.state.activeConsultation &&
        consultationId === this.state.activeConsultation._id
      ) {
        return;
      }
      const { consultation, patient } = this.state.consultationMessages[consultationId];
      this.setState({
        showChatView: true,
        isChatLoading: true,
        isBroadcastView: false,
        activeBroadcast: null,
        activeConversation: null,
        activeConsultation: consultation,
        activePatient: patient,
      });
      // TODO: figure out how to handle multiple connections from the same practitioner
      // WebSocket.getConsultationMessages(this.socketRef.current, {
      //   consultationId,
      //   practitionerId: sessionStorage.getItem('practitioner_id'),
      //   organizationId: sessionStorage.getItem('organization_id'),
      // });

      getConsultationMessages(consultationId).then(msgs => {
        this.setState({
          messages: msgs.reverse(),
          isChatLoading: false,
          showChatView: true,
        });
      });
    }
  };

  newSinglePatientMessage = patient => {
    this.setState({ showPatientsModal: false });
    this.onMessageSelect({
      patientId: patient.id,
      consultationId: null,
      conversationId: null,
      broadcastId: null,
    });
  };

  newBroadcastMessage = patients => {
    const orgMsgs = { ...this.state.organizationMessages };
    const tmpBroadcast = { _id: 'temp_broadcast_id', recipients: patients };
    orgMsgs['temp_broadcast_id'] = {
      broadcast: tmpBroadcast,
      recipients: patients,
      preview: { broadcast_id: 'temp_broadcast_id' },
    };
    this.setState({
      messages: [],
      organizationMessages: orgMsgs,
      activeBroadcast: tmpBroadcast,
      showChatView: true,
      showPatientsModal: false,
      activeConversation: null,
      activePatient: null,
      isChatLoading: false,
      broadcastRecipients: patients,
      isBroadcastView: true,
      openConsultationForActivePatient: false,
    });
  };

  newMessage = patients => {
    if (patients.length === 1) {
      this.newSinglePatientMessage(patients[0]);
    } else {
      this.newBroadcastMessage(patients);
    }
  };

  getConversationByPatientIdOrConversationId(patientId, conversationId = null) {
    if (this.state.organizationMessages[conversationId]) {
      return this.state.organizationMessages[conversationId].conversation;
    }

    for (const entry of Object.values(this.state.organizationMessages)) {
      if (entry.conversation && entry.conversation.patient_id === patientId) {
        return entry.conversation;
      }
    }
    return null;
  }

  isSingleMessageEntryActive(msg) {
    if (msg.consultation) {
      return (
        this.state.activeConsultation &&
        this.state.activeConsultation._id === msg.consultation._id
      );
    }
    return (
      this.state.activePatient &&
      this.state.activePatient.id === msg.conversation.patient_id
    );
  }

  isBroadcastEntryActive(broadcast) {
    return this.state.activeBroadcast && this.state.activeBroadcast._id === broadcast._id;
  }

  sortCompareFn(msgA, msgB) {
    if (msgA.preview && msgB.preview) {
      return msgA.preview.sent_ts < msgB.preview.sent_ts ? 1 : -1;
    }
    if (!msgA.preview) {
      return -1;
    }
    return 1;
  }

  render() {
    const messageList =
      this.state.activeTab === 'messages'
        ? Object.values(this.state.organizationMessages).sort(this.sortCompareFn)
        : this.state.filteredConsultationMessages.sort(this.sortCompareFn);

    return (
      <>
        <Layout>
          <MediaQuery minWidth={768}>
            <Row className="m-0">
              {/* Left sidebar (on Desktop) */}
              <Col sm={4} className="h-100 bg-white p-0 overflow-auto">
                <div className="sticky-top w-100 bg-white">
                  <Row className="consultation-page m-0">
                    <Nav className="w-100" tabs justified={true}>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === 'messages',
                          })}
                          onClick={() => {
                            this.toggleTab('messages');
                          }}
                        >
                          Messages
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === 'consultations',
                          })}
                          onClick={() => {
                            this.toggleTab('consultations');
                          }}
                        >
                          Consultations
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Row>
                  {this.state.activeTab === 'messages' ? (
                    <Row className="m-0 bg-light-gray">
                      <div
                        className="w-100 p-2 d-flex flex-row justify-content-between"
                        onClick={this.togglePatientsModal}
                      >
                        <p className="my-auto">New Message</p>
                        <button
                          type="button"
                          className="button-reset"
                          onClick={this.togglePatientsModal}
                        >
                          <img className="m-2" src={MessageIcon} alt="new message" />
                        </button>
                      </div>
                    </Row>
                  ) : (
                    <Row className="w-100 m-0">
                      <div className="w-100 p-2 d-flex flex-row justify-content-between">
                        <div className="my-auto">View:</div>
                        <div className="consultation-status">
                          <select
                            className=""
                            value={this.state.consultationsViewStatus}
                            onChange={this.selectConsultationStatus}
                          >
                            <option value="active">
                              Active Consultations ({this.state.activeCount})
                            </option>
                            <option value="closed">
                              Closed Consultations ({this.state.closedCount})
                            </option>
                            <option value="all">
                              All Consultations ({this.state.allCount})
                            </option>
                          </select>
                        </div>
                      </div>
                    </Row>
                  )}
                </div>
                {/* Container for list of patient messages */}
                <Row className="w-100 m-0 pb-5">
                  {this.state.isMessagesLoading ? (
                    <div className="p-4 m-auto">
                      <Spinner
                        className="loading-spinner"
                        style={{ width: '3rem', height: '3rem' }}
                      />
                    </div>
                  ) : (
                    messageList.map((msg, idx) =>
                      msg.broadcast ? (
                        <BroadcastMessageEntry
                          key={idx}
                          message={msg.preview}
                          onSelect={this.onMessageSelect}
                          isActive={this.isBroadcastEntryActive(msg.broadcast)}
                          recipients={msg.recipients}
                          broadcastId={msg.broadcast._id}
                        />
                      ) : (
                        <SingleMessageEntry
                          key={idx}
                          patient={msg.patient}
                          message={msg.preview}
                          onSelect={this.onMessageSelect}
                          conversationId={msg.conversation ? msg.conversation._id : null}
                          consultationId={msg.consultation ? msg.consultation._id : null}
                          isActive={this.isSingleMessageEntryActive(msg)}
                          status={msg.consultation ? msg.consultation.status : null}
                        />
                      )
                    )
                  )}
                </Row>
              </Col>
              {/* Chat view */}
              <Col className="h-100 bg-gray w-100 p-2 position-relative">
                {!this.state.showChatView ? (
                  <div className="px-4 py-2 font-weight-normal">
                    {this.state.activeTab === 'messages'
                      ? 'Please select a patient message.'
                      : 'Please select a consultation.'}
                  </div>
                ) : this.state.isChatLoading ? (
                  <div className="w-100 p-4 d-flex justify-content-center">
                    <Spinner
                      className="loading-spinner m-auto"
                      style={{ width: '3rem', height: '3rem' }}
                    />
                  </div>
                ) : (
                  <ChatView
                    patient={this.state.activePatient}
                    consultation={this.state.activeConsultation}
                    recipients={this.state.broadcastRecipients}
                    isBroadcast={this.state.isBroadcastView}
                    openConsultation={this.state.openConsultationForActivePatient}
                    messages={this.state.messages}
                    practitioners={this.state.practitioners}
                    sendMessage={this.sendMessage}
                    startConsultation={this.startConsultation}
                    restartConsultation={this.restartConsultation}
                    requestPayment={this.requestPayment}
                    goToOpenConsultation={this.goToOpenConsultation}
                  />
                )}
              </Col>
            </Row>
          </MediaQuery>
          {/* Mobile */}
          <MediaQuery maxWidth={767}>
            <Row className="m-0">
              {!this.state.showChatView ? (
                <Col className="h-100 bg-white p-0 overflow-auto">
                  <div className="sticky-top w-100 bg-white">
                    <Row className="consultation-page m-0">
                      <Nav className="w-100" tabs justified={true}>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === 'messages',
                            })}
                            onClick={() => {
                              this.toggleTab('messages');
                            }}
                          >
                            Messages
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === 'consultations',
                            })}
                            onClick={() => {
                              this.toggleTab('consultations');
                            }}
                          >
                            Consultations
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Row>
                    {this.state.activeTab === 'messages' ? (
                      <Row className="m-0 bg-light-gray">
                        <div
                          className="w-100 p-2 d-flex flex-row justify-content-between"
                          onClick={this.togglePatientsModal}
                        >
                          <p className="my-auto">New Message</p>
                          <button
                            type="button"
                            className="button-reset"
                            onClick={this.togglePatientsModal}
                          >
                            <img className="m-2" src={MessageIcon} alt="new message" />
                          </button>
                        </div>
                      </Row>
                    ) : (
                      <Row className="w-100 m-0">
                        <div className="w-100 p-2 d-flex flex-row justify-content-between">
                          <div className="my-auto">View:</div>
                          <div className="consultation-status">
                            <select
                              className=""
                              value={this.state.consultationsViewStatus}
                              onChange={this.selectConsultationStatus}
                            >
                              <option value="active">
                                Active Consultations ({this.state.activeCount})
                              </option>
                              <option value="closed">
                                Closed Consultations ({this.state.closedCount})
                              </option>
                              <option value="all">
                                All Consultations ({this.state.allCount})
                              </option>
                            </select>
                          </div>
                        </div>
                      </Row>
                    )}
                  </div>
                  <Row className="w-100 m-0 pb-5">
                    {this.state.isMessagesLoading ? (
                      <div className="p-4 m-auto">
                        <Spinner
                          className="loading-spinner"
                          style={{ width: '3rem', height: '3rem' }}
                        />
                      </div>
                    ) : (
                      messageList.map((msg, idx) =>
                        msg.broadcast ? (
                          <BroadcastMessageEntry
                            key={idx}
                            message={msg.preview}
                            onSelect={this.onMessageSelect}
                            isActive={this.isBroadcastEntryActive(msg.broadcast)}
                            recipients={msg.recipients}
                            broadcastId={msg.broadcast._id}
                          />
                        ) : (
                          <SingleMessageEntry
                            key={idx}
                            patient={msg.patient}
                            message={msg.preview}
                            onSelect={this.onMessageSelect}
                            conversationId={
                              msg.conversation ? msg.conversation._id : null
                            }
                            consultationId={
                              msg.consultation ? msg.consultation._id : null
                            }
                            isActive={this.isSingleMessageEntryActive(msg)}
                            status={msg.consultation ? msg.consultation.status : null}
                          />
                        )
                      )
                    )}
                  </Row>
                </Col>
              ) : (
                <Col className="h-100 bg-gray w-100 p-2 position-relative">
                  <button
                    className="button-reset px-2 py-1"
                    onClick={this.showMessagesList}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                      />
                    </svg>{' '}
                    Back
                  </button>
                  {this.state.isChatLoading ? (
                    <div className="w-100 p-4 d-flex justify-content-center">
                      <Spinner
                        className="loading-spinner m-auto"
                        style={{ width: '3rem', height: '3rem' }}
                      />
                    </div>
                  ) : (
                    <ChatView
                      patient={this.state.activePatient}
                      consultation={this.state.activeConsultation}
                      recipients={this.state.broadcastRecipients}
                      isBroadcast={this.state.isBroadcastView}
                      openConsultation={this.state.openConsultationForActivePatient}
                      messages={this.state.messages}
                      practitioners={this.state.practitioners}
                      sendMessage={this.sendMessage}
                      startConsultation={this.startConsultation}
                      restartConsultation={this.restartConsultation}
                      requestPayment={this.requestPayment}
                      goToOpenConsultation={this.goToOpenConsultation}
                    />
                  )}
                </Col>
              )}
            </Row>
          </MediaQuery>
        </Layout>

        <PatientsModal
          patients={Object.values(this.state.patients)}
          show={this.state.showPatientsModal}
          toggle={this.togglePatientsModal}
          onSelect={this.newMessage}
        />
      </>
    );
  }
}

export default Messaging;
