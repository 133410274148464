import React, { Fragment } from 'react';

const Avatar = props => {
  const colors = {
    A: '#E5DDD5',
    B: '#cdecdd',
    C: '#afd9c8',
    D: '#7acca6',
    E: '#c8eaec',
    F: '#aadcd9',
    G: '#67d6da',
    H: '#6ec4d5',
    I: '#f3dbd6',
    J: '#f3d6e2',
    K: '#fbcbd3',
    L: '#ffa9a9',
    M: '#ccdbed',
    N: '#d8d4ec',
    O: '#d8d4ec',
    P: '#d1dfb3',
    Q: '#dfe1b6',
    R: '#e7e0aa',
    S: '#f7eaaa',
    T: '#ffd2a5',
    U: '#ff8b8d',
    V: '#ff5878',
    W: '#f66055',
    X: '#dd6e4e',
    Y: '#e7e465',
    Z: '#73c981',
  };

  const getAvatarColorFromLetter = letter => {
    let toCapital = letter.toUpperCase();
    return colors[toCapital];
  };

  const getAvatarDimensions = (type = 'list') => {
    if (type === 'patient-details') {
      return '2em';
    }
    return '1em';
  };

  const styles = {
    background: getAvatarColorFromLetter(props.letter),
    display: 'inline-block',
    fontSize: getAvatarDimensions(props.type),
    width: '2.5em',
    height: '2.5em',
    lineHeight: '2.5em',
    textAlign: 'center',
    borderRadius: '50%',
    margin: '0',
    color: 'white',
  };

  return (
    <Fragment>
      <p data-avatar={props.letter} style={styles}></p>
    </Fragment>
  );
};

export default Avatar;
