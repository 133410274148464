import React from 'react';
import PropTypes from 'prop-types';

const ActionButton = ({ icon, label, clickHandler }) => {
  return (
    <button
      className="button-reset d-flex flex-column p-2"
      type="button"
      onClick={clickHandler}
    >
      <img className="mx-auto w-2" src={icon} alt={label} />
      <span className="text-center text-sm pt-2">{label}</span>
    </button>
  );
};

ActionButton.propTypes = {
  icon: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
};

export default ActionButton;
