import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
} from 'reactstrap';
import DatePicker from 'reactstrap-date-picker';

function ConsultationCloseModal({ show, toggle, onSubmit }) {
  const [study_id, setStudyId] = useState('');
  const [studyIdError, setStudyIdError] = useState(false);
  const [anc_number, setAncNumber] = useState('');
  const [ancNumberError, setAncNumberError] = useState(false);
  const [lmp, setLmp] = useState();
  const [lmpError, setLmpError] = useState();
  const [edd, setEdd] = useState();
  const [eddError, setEddError] = useState();
  const [current_gestation, setCurrentGestation] = useState();
  const [currentGestationError, setCurrentGestationError] = useState(false);
  const [proceed_with_consultation, setProceedWithConsultation] = useState();
  const [proceedWithConsultationError, setProceedWithConsultationError] = useState();
  const [hideConsultationForm, setHideConsultationForm] = useState(true);
  const [hideConsultationOptOut, setHideConsultationOptOut] = useState(true);
  const [consultation_opt_out_reason, setConsultationOptOutReason] = useState('');
  const [consultation_opt_out_reason_other, setConsultationOptOutReasonOther] =
    useState('');
  const [chief_complaint, setChiefComplaint] = useState();
  const [chief_complaint_description, setChiefComplaintDescription] = useState('');
  const [danger_signs_followup, setDangerSignsFollowup] = useState([]);
  const [danger_signs_followup_other, setDangerSignsFollowupOther] = useState('');
  const [llitn, setLlitn] = useState('');
  const [ifas, setIfas] = useState('');
  const [visit_risk_factors, setVisitRiskFactors] = useState();
  const [risk_factors_description, setRiskFactorsDescription] = useState('');
  const [risk_factors_management_given, setRiskFactorsManagemetGiven] = useState('');
  const [practitioner_name, setPractitionerName] = useState('');
  const [consultationNotes, setConsultationNotes] = useState('');
  const [consultationNotesError, setConsultationNotesError] = useState(false);
  const [treatmentNotes, setTreatmentNotes] = useState('');
  const [treatmentNotesError, setTreatmentNotesError] = useState(false);
  const [followUpMessageTime, setFollowUpMessageTime] = useState([]);
  const [followUpAppointmentTs, setFollowUpAppointmentTs] = useState();

  const toggleFollowUpMessageTime = evt => {
    evt.preventDefault();
    const target = evt.currentTarget;
    let current = [...followUpMessageTime];
    const eventOption = target.dataset.option;
    if (current.includes(eventOption)) {
      current = current.filter(item => item !== eventOption);
    } else {
      current.push(eventOption);
    }
    setFollowUpMessageTime(current);
  };

  const onfollowUpAppointmentDateChange = (_, date) => {
    if (!date) {
      setFollowUpAppointmentTs(undefined);
    } else if (!followUpAppointmentTs) {
      setFollowUpAppointmentTs(new Date(date));
    } else {
      const newDate = new Date(date);
      newDate.setHours(followUpAppointmentTs.getHours());
      newDate.setMinutes(followUpAppointmentTs.getMinutes());
      setFollowUpAppointmentTs(newDate);
    }
  };

  const onfollowUpAppointmentTimeChange = evt => {
    const [hours, minutes] = evt.currentTarget.value.split(':');
    console.log(hours, minutes);
    if (!followUpAppointmentTs) {
      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);
      setFollowUpAppointmentTs(date);
    } else {
      const date = new Date(followUpAppointmentTs);
      date.setHours(hours);
      date.setMinutes(minutes);
      setFollowUpAppointmentTs(date);
    }
  };

  const onStudyIdChange = evt => {
    setStudyId(evt.target.value);
    setStudyIdError(false);
  };
  const onAncNumberChange = evt => {
    setAncNumber(evt.target.value);
    setAncNumberError(false);
  };
  const onLmpChange = evt => {
    setLmp(evt.target.value);
    setLmpError(false);
  };
  const onEddChange = evt => {
    setEdd(evt.target.value);
    setEddError(false);
  };
  const onCurrentGestationChange = evt => {
    setCurrentGestation(evt.target.value);
    setCurrentGestationError(false);
  };
  const onProceedWithConsultationChange = evt => {
    setProceedWithConsultationError(false);
    if (evt.target.value === 'yes') {
      setProceedWithConsultation(true);
      setHideConsultationForm(false);
      setHideConsultationOptOut(true);
    } else {
      setProceedWithConsultation(false);
      setHideConsultationForm(true);
      setHideConsultationOptOut(false);
    }
  };
  const onConsultationOptOutReasonChange = evt => {
    setConsultationOptOutReason(evt.target.value);
  };

  const onConsultationOptOutReasonOtherChange = evt => {
    setConsultationOptOutReasonOther(evt.target.value);
  };
  const onChiefComplaintChange = evt => {
    if (evt.target.value === 'yes') {
      setChiefComplaint(true);
    } else {
      setChiefComplaint(false);
    }
  };
  const onChiefComplaintDescriptionChange = evt => {
    setChiefComplaintDescription(evt.target.value);
  };
  const onDangerSignsFollowup = evt => {
    const { checked, value } = evt.target;
    var signs = danger_signs_followup;
    if (checked) {
      if (!signs.includes(value)) {
        signs.push(value);
        setDangerSignsFollowup(signs);
      }
    } else {
      if (signs.includes(value)) {
        const filteredSigns = signs.filter(item => value !== item);
        setDangerSignsFollowup(filteredSigns);
      }
    }
  };
  const onDangerSignsFollowupOtherChange = evt => {
    setDangerSignsFollowupOther(evt.target.value);
  };
  const onIfasUseChange = evt => {
    if (evt.target.value === 'yes') {
      setIfas(true);
    } else {
      setIfas(false);
    }
  };
  const onLlitnUseChange = evt => {
    setLlitn(evt.target.value);
  };
  const onRiskFactorsPresentChange = evt => {
    if (evt.target.value === 'yes') {
      setVisitRiskFactors(true);
    } else {
      setVisitRiskFactors(false);
    }
  };
  const onRiskFactorsDescriptionChange = evt => {
    setRiskFactorsDescription(evt.target.value);
  };
  const onRiskFactorsManagementGivenChange = evt => {
    setRiskFactorsManagemetGiven(evt.target.value);
  };

  const onPractitionerNameChange = evt => {
    setPractitionerName(evt.target.value);
  };
  const onConsultationNotesChange = evt => {
    setConsultationNotes(evt.target.value);
    setConsultationNotesError(false);
  };

  const onTreatmentNotesChange = evt => {
    setTreatmentNotes(evt.target.value);
    setTreatmentNotesError(false);
  };

  const submitSummary = evt => {
    evt.preventDefault();
    let hasError = false;
    if (!study_id.trim().length) {
      setStudyIdError(true);
      hasError = true;
    }
    if (!anc_number.trim().length) {
      setAncNumberError(true);
      hasError = true;
    }
    if (!edd) {
      setEddError(true);
      hasError = true;
    }
    if (!lmp) {
      setLmpError(true);
      hasError = true;
    }
    if (!current_gestation) {
      setCurrentGestationError(true);
      hasError = true;
    }

    if (!consultationNotes.trim().length) {
      setConsultationNotesError(true);
      hasError = true;
    }

    if (!treatmentNotes.trim().length) {
      setTreatmentNotesError(true);
      hasError = true;
    }

    if (proceed_with_consultation !== true && proceed_with_consultation !== false) {
      setProceedWithConsultationError(true);
      hasError = true;
    }

    if (!hasError) {
      const formType = 'mch_consultation';
      onSubmit({
        consultationNotes,
        treatmentNotes,
        form: formType,
        reportDate: new Date().toISOString(),
        fields: {
          study_id,
          anc_number,
          lmp,
          edd,
          current_gestation,
          consultation: proceed_with_consultation,
          consultation_opt_out_reason,
          consultation_opt_out_reason_other,
          chief_complaint,
          chief_complaint_description,
          danger_signs_followup,
          danger_signs_followup_other,
          llitn,
          visit_risk_factors,
          risk_factors_description,
          risk_factors_management_given,
          practitioner_name,
        },
        followup: {
          messageAt: followUpMessageTime,
          nextAppointmentTs: followUpAppointmentTs,
        },
      });
    }
  };

  return (
    <>
      <Modal isOpen={show} toggle={toggle} scrollable={true} size="lg">
        <ModalHeader toggle={toggle}>Consultation Summary</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="study_id" className="font-weight-normal">
                Study ID
              </Label>
              <Input
                className={studyIdError && 'validation-error-input'}
                name="study_id"
                id="study_id"
                value={study_id}
                placeholder="Program study ID"
                onChange={onStudyIdChange}
              />
              {studyIdError && (
                <p className="px-1 validation-error-text">Field is required.</p>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="anc_number" className="font-weight-normal">
                ANC Number
              </Label>
              <Input
                className={ancNumberError && 'validation-error-input'}
                name="anc_number"
                id="anc_number"
                value={anc_number}
                onChange={onAncNumberChange}
              />
              {ancNumberError && (
                <p className="px-1 validation-error-text">Field is required.</p>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="lmp" className="font-weight-normal">
                LMP
              </Label>
              <Input
                className={lmpError && 'validation-error-input'}
                name="lmp"
                id="lmp"
                value={lmp}
                type="date"
                onChange={onLmpChange}
              />
              {lmpError && (
                <p className="px-1 validation-error-text">Field is required.</p>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="edd" className="font-weight-normal">
                EDD
              </Label>
              <Input
                className={eddError && 'validation-error-input'}
                name="edd"
                id="edd"
                value={edd}
                type="date"
                onChange={onEddChange}
              />
              {eddError && (
                <p className="px-1 validation-error-text">Field is required.</p>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="current_gestation" className="font-weight-normal">
                Current gestation
              </Label>
              <Input
                className={currentGestationError && 'validation-error-input'}
                name="current_gestation"
                id="current_gestation"
                value={current_gestation}
                type="number"
                onChange={onCurrentGestationChange}
              />
              {currentGestationError && (
                <p className="px-1 validation-error-text">Field is required.</p>
              )}
            </FormGroup>

            <FormGroup row tag="fieldset">
              <Label sm={10} className="font-weight-normal">
                Do you want to start a remote consultation?
              </Label>
              <Col sm={10}>
                <FormGroup check>
                  <Input
                    name="proceed_with_consultation"
                    value="yes"
                    type="radio"
                    checked={proceed_with_consultation === true}
                    onChange={onProceedWithConsultationChange}
                  />{' '}
                  <Label check>Yes</Label>
                </FormGroup>
                <FormGroup check>
                  <Input
                    name="stop_consultation"
                    value="no"
                    type="radio"
                    checked={proceed_with_consultation === false}
                    onChange={onProceedWithConsultationChange}
                  />{' '}
                  <Label check>No</Label>
                </FormGroup>
              </Col>
              {proceedWithConsultationError && (
                <p className="px-1 validation-error-text">Field is required.</p>
              )}
            </FormGroup>
            <div hidden={hideConsultationOptOut}>
              <FormGroup row tag="fieldset">
                <Label sm={10} className="font-weight-normal">
                  Consultation opt out reason:
                </Label>
                <Col sm={10}>
                  <FormGroup check>
                    <Input
                      name="abortion"
                      type="radio"
                      value={'Abortion'}
                      checked={consultation_opt_out_reason === 'Abortion'}
                      onChange={onConsultationOptOutReasonChange}
                    />{' '}
                    <Label check>Abortion</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="miscarriage"
                      type="radio"
                      value={'Miscarriage'}
                      checked={consultation_opt_out_reason === 'Miscarriage'}
                      onChange={onConsultationOptOutReasonChange}
                    />{' '}
                    <Label check>Miscarriage</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="refused_care"
                      type="radio"
                      value={'Refused Care'}
                      checked={consultation_opt_out_reason === 'Refused Care'}
                      onChange={onConsultationOptOutReasonChange}
                    />{' '}
                    <Label check>Refused Care</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="migrated_out_of_study_area"
                      checked={
                        consultation_opt_out_reason === 'Migrated out of study area'
                      }
                      value="Migrated out of study area"
                      type="radio"
                      onChange={onConsultationOptOutReasonChange}
                    />{' '}
                    <Label check>Migrated out of study area</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="patient_declined_to_participate"
                      checked={
                        consultation_opt_out_reason === 'Patient declined to participate'
                      }
                      value="Patient declined to participate"
                      type="radio"
                      onChange={onConsultationOptOutReasonChange}
                    />{' '}
                    <Label check>Patient declined to participate</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="other_reason_for_opt_out"
                      value="Other"
                      type="radio"
                      checked={consultation_opt_out_reason === 'Other'}
                      onChange={onConsultationOptOutReasonChange}
                    />{' '}
                    <Label check>Other</Label>
                  </FormGroup>
                </Col>
              </FormGroup>

              <FormGroup>
                <Label
                  for="consultation_opt_out_reason_other"
                  className="font-weight-normal"
                >
                  Consultation opt-out reason (Other).
                </Label>
                <Input
                  type="textarea"
                  name="consultation_opt_out_reason_other"
                  id="consultation_opt_out_reason_other"
                  value={consultation_opt_out_reason_other}
                  onChange={onConsultationOptOutReasonOtherChange}
                />
              </FormGroup>
            </div>
            <div hidden={hideConsultationForm}>
              <FormGroup row tag="fieldset">
                <Label sm={10} className="font-weight-normal">
                  Do you have any chief complaint?
                </Label>
                <Col sm={10}>
                  <FormGroup check>
                    <Input
                      name="chief_complaint_yes"
                      value="yes"
                      type="radio"
                      checked={chief_complaint === true}
                      onChange={onChiefComplaintChange}
                    />{' '}
                    <Label check>Yes</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="chief_complaint_no"
                      value="no"
                      type="radio"
                      checked={chief_complaint === false}
                      onChange={onChiefComplaintChange}
                    />{' '}
                    <Label check>No</Label>
                  </FormGroup>
                </Col>
              </FormGroup>

              <FormGroup>
                <Label for="chief_complaint_description" className="font-weight-normal">
                  If Yes to question above, describe the complaints and management given.
                </Label>
                <Input
                  type="textarea"
                  name="chief_complaint_description"
                  id="chief_complaint_description"
                  value={chief_complaint_description}
                  onChange={onChiefComplaintDescriptionChange}
                />
              </FormGroup>

              <FormGroup row tag="fieldset">
                <Label sm={10} className="font-weight-normal">
                  Is the patient experiencing any of the following danger signs?
                </Label>
                <Col sm={10}>
                  <FormGroup check>
                    <Input
                      name="vaginal_bleeding"
                      id="vaginal_bleeding"
                      value={'Vaginal bleeding'}
                      onChange={onDangerSignsFollowup}
                      type="checkbox"
                    />{' '}
                    <Label check>Vaginal bleeding</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="abdominal_pain"
                      id="abdominal_pain"
                      value={'Abdominal pain'}
                      onChange={onDangerSignsFollowup}
                      type="checkbox"
                    />{' '}
                    <Label check>Abdominal pain</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="headache"
                      id="headache"
                      value={'Headache'}
                      onChange={onDangerSignsFollowup}
                      type="checkbox"
                    />{' '}
                    <Label check>Headache</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="easy_fatigability"
                      id="easy_fatigability"
                      value={'Easy fatigability'}
                      onChange={onDangerSignsFollowup}
                      type="checkbox"
                    />{' '}
                    <Label check>Easy fatigability</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="fever"
                      id="fever"
                      value={'Fever'}
                      onChange={onDangerSignsFollowup}
                      type="checkbox"
                    />{' '}
                    <Label check>Fever</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="reduced_fetal_movements"
                      id="reduced_fetal_movements"
                      onChange={onDangerSignsFollowup}
                      value={'Reduced fetal movements'}
                      type="checkbox"
                    />{' '}
                    <Label check>Reduced fetal movements</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="swelling_of_the_face_hands_legs"
                      id="swelling_of_the_face_hands_legs"
                      value={'Swelling of the face, hands and legs'}
                      onChange={onDangerSignsFollowup}
                      type="checkbox"
                    />{' '}
                    <Label check>Swelling of the face, hands and legs</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="breathlessness"
                      id="breathlessness"
                      value={'Breathlessness'}
                      onChange={onDangerSignsFollowup}
                      type="checkbox"
                    />{' '}
                    <Label check>Breathlessness</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="danger_sign_other"
                      id="danger_sign_other"
                      value={'Other'}
                      onChange={onDangerSignsFollowup}
                      type="checkbox"
                    />{' '}
                    <Label check>Other</Label>
                  </FormGroup>
                </Col>
              </FormGroup>

              <FormGroup>
                <Label for="danger_signs_followup_other" className="font-weight-normal">
                  Danger sign follow up (Other)
                </Label>
                <Input
                  type="textarea"
                  name="danger_signs_followup_other"
                  id="danger_signs_followup_other"
                  value={danger_signs_followup_other}
                  onChange={onDangerSignsFollowupOtherChange}
                />
              </FormGroup>

              <FormGroup row tag="fieldset">
                <Label sm={10} className="font-weight-normal">
                  Are you taking IFAS?
                </Label>
                <Col sm={10}>
                  <FormGroup check>
                    <Input
                      name="ifas_yes"
                      type="radio"
                      checked={ifas === true}
                      value="yes"
                      onChange={onIfasUseChange}
                    />{' '}
                    <Label check>Yes</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="ifas_no"
                      type="radio"
                      checked={ifas === false}
                      value={false}
                      onChange={onIfasUseChange}
                    />{' '}
                    <Label check>No</Label>
                  </FormGroup>
                </Col>
              </FormGroup>

              <FormGroup row tag="fieldset">
                <Label sm={10} className="font-weight-normal">
                  Are you using Long Life Insecticide treated net?
                </Label>
                <Col sm={10}>
                  <FormGroup check>
                    <Input
                      name="llitn_yes"
                      type="radio"
                      checked={llitn === 'yes'}
                      value={'yes'}
                      onChange={onLlitnUseChange}
                    />{' '}
                    <Label check>Yes</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="llitn_no"
                      type="radio"
                      checked={llitn === 'no'}
                      value={'no'}
                      onChange={onLlitnUseChange}
                    />{' '}
                    <Label check>No</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="llitn_maybe"
                      type="radio"
                      checked={llitn === 'maybe'}
                      value={'maybe'}
                      onChange={onLlitnUseChange}
                    />{' '}
                    <Label check>Maybe</Label>
                  </FormGroup>
                </Col>
              </FormGroup>

              <FormGroup row tag="fieldset">
                <Label sm={10}>Is the mother presenting any risk factors?</Label>
                <Col sm={10}>
                  <FormGroup check>
                    <Input
                      name="risk_factors_yes"
                      type="radio"
                      value="yes"
                      checked={visit_risk_factors === true}
                      onChange={onRiskFactorsPresentChange}
                    />{' '}
                    <Label check>Yes</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="risk_factors_no"
                      type="radio"
                      checked={visit_risk_factors === false}
                      value="no"
                      onChange={onRiskFactorsPresentChange}
                    />{' '}
                    <Label check>No</Label>
                  </FormGroup>
                </Col>
              </FormGroup>

              <FormGroup>
                <Label for="risk_factors_description" className="font-weight-normal">
                  Describe the risk factor the patient presents today
                </Label>
                <Input
                  type="textarea"
                  name="risk_factors_description"
                  id="risk_factors_description"
                  value={risk_factors_description}
                  onChange={onRiskFactorsDescriptionChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="risk_factors_management_given" className="font-weight-normal">
                  Describe the management given today
                </Label>
                <Input
                  type="textarea"
                  name="risk_factors_management_given"
                  id="risk_factors_management_given"
                  value={risk_factors_management_given}
                  onChange={onRiskFactorsManagementGivenChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="practitioner_name" className="font-weight-normal">
                  Name of Practitioner
                </Label>
                <Input
                  name="practitioner_name"
                  id="practitioner_name"
                  value={practitioner_name}
                  onChange={onPractitionerNameChange}
                />
              </FormGroup>
            </div>
            <FormGroup>
              <Label for="consultation_notes" className="font-weight-normal">
                Consultation Notes and History
              </Label>
              <Input
                type="textarea"
                className={consultationNotesError && 'validation-error-input'}
                name="consultation_notes"
                id="consultation_notes"
                value={consultationNotes}
                placeholder="Write any notes you want the patient to receive here"
                onChange={onConsultationNotesChange}
              />
              {consultationNotesError && (
                <p className="px-1 validation-error-text">Field is required.</p>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="treatment" className="font-weight-normal">
                Treatment Notes
              </Label>
              <Input
                type="textarea"
                className={treatmentNotesError && 'validation-error-input'}
                name="treatment"
                id="treatment"
                rows={5}
                value={treatmentNotes}
                placeholder={
                  'Write any notes here for follow-up and treatment that the patient might need for ex. "Take ciproflaxin 2x2 daily before breakfast and dinner", or "Avoid stressful or strenuous activity for the next few weeks"'
                }
                onChange={onTreatmentNotesChange}
              />
              {treatmentNotesError && (
                <p className="px-1 validation-error-text">Field is required.</p>
              )}
            </FormGroup>
            <div>
              <p>
                <strong>Send Follow-up Message</strong>
              </p>
              <div>
                <Button
                  color={followUpMessageTime.includes('tonight') && 'primary'}
                  data-option={'tonight'}
                  className="mr-1 rounded-pill"
                  onClick={toggleFollowUpMessageTime}
                >
                  Tonight
                </Button>
                <Button
                  data-option={'3-days'}
                  color={followUpMessageTime.includes('3-days') && 'primary'}
                  className="rounded-pill"
                  onClick={toggleFollowUpMessageTime}
                >
                  After 3 Days
                </Button>
              </div>
            </div>
            <div className="mt-2">
              <p>
                <strong>Follow up appointment</strong>
              </p>
              <FormGroup>
                <DatePicker
                  value={followUpAppointmentTs && followUpAppointmentTs.toISOString()}
                  minDate={new Date().toISOString()}
                  dateFormart={'YYYY/MM/DD'}
                  id="appointment-datepicker"
                  on
                  onChange={onfollowUpAppointmentDateChange}
                />
              </FormGroup>
              <FormGroup style={{ display: 'none' }}>
                {/** hide this for now as it is not yet in use */}
                <Input
                  disabled={!followUpAppointmentTs}
                  id="appointment-timepicker"
                  type="time"
                  onChange={onfollowUpAppointmentTimeChange}
                />
              </FormGroup>
            </div>
            <ModalFooter className="border-0">
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>{' '}
              <Button color="primary" onClick={submitSummary} type="submit">
                Done
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

ConsultationCloseModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default ConsultationCloseModal;
