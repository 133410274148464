import Africastalking from 'africastalking-client';
import { createVoiceClient, refreshVoiceClient } from './backendService';

import Ringtone from '../Assets/ring.mp3';
import Dialtone from '../Assets/dial.mp3';

export const CallType = {
  INCOMING: 'incoming',
  OUTGOING: 'outgoing',
};

export const CallState = {
  DIALING: 'dialing',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  RINGING: 'ringing',
};

export const ATClientEvent = {
  READY: 'ready', // Client is ready to make or recieve calls
  NOT_READY: 'notready', // Client cannot make or recieve calls,
  CALLING: 'calling', // Client is making a call
  INCOMING_CALL: 'incomingcall', // Client is recieving a call
  CALL_ACCEPTED: 'callaccepted', // Call has been accepted
  HANG_UP: 'hangup', // Call has ended
  OFFLINE: 'offline', // Token has expired
  CLOSED: 'closed', // Connection to africastalking servers closed
};

export const ATClientActions = {
  CALL: 'call',
  ANSWER: 'answer',
  HANG_UP: 'hangup',
  MUTE: 'muteAudio',
  UNMUTE: 'unmuteAudio',
  HOLD: 'hold',
  UN_HOLD: 'unhold',
};

let clientConfig;
function getClientConfig() {
  if (clientConfig) return clientConfig;
  const clientConfigStr = localStorage.getItem('at_client');
  try {
    clientConfig = JSON.parse(clientConfigStr);
    return clientConfig;
  } catch {
    return null;
  }
}

function saveClientConfig(config) {
  clientConfig = config;
  localStorage.setItem('at_client', JSON.stringify(config));
}

export function deviceIsRegistered() {
  return !!getClientConfig();
}

export function tokenIsExpired() {
  const { expiresAt } = getClientConfig();
  return new Date(expiresAt).getTime() - Date.now() < 0;
}

export function deviceCanReceiveAndMakeCalls() {
  const client = getClientConfig();
  return client && client.outgoingEnabled && client.incomingEnabled;
}

async function refreshWebRTCClient() {
  const updatedClient = await refreshVoiceClient(getClientConfig().name);
  saveClientConfig(updatedClient);
}

let cachedAtClient;
export async function getWebRTCClient() {
  if (tokenIsExpired()) {
    console.log('Refreshing capability token.');
    await refreshWebRTCClient();
    cachedAtClient = null;
  }

  if (!cachedAtClient) {
    const clientConfig = getClientConfig();
    cachedAtClient = new Africastalking.Client(clientConfig.token, {
      sounds: { ringing: Ringtone, dialing: Dialtone },
    });
  } else {
    console.log('Using a cached version of AT client');
  }

  return cachedAtClient;
}

export async function createWebRTCClient(preferences) {
  const organizationId = sessionStorage.getItem('organization_id');
  const practitionerId = sessionStorage.getItem('practitioner_id');

  try {
    const client = await createVoiceClient({
      organizationId,
      practitionerId,
      ...preferences,
    });

    saveClientConfig(client);
  } catch (err) {
    console.error('Error creating a webrtc client', err);
  }
}

export function executeClientAction(client, action, payload) {
  switch (action) {
    case ATClientActions.CALL:
      client.call(payload);
      break;
    case ATClientActions.ANSWER:
      client.answer();
      break;
    case ATClientActions.HANG_UP:
      client.hangup();
      break;
    case ATClientActions.MUTE:
      client.muteAudio();
      break;
    case ATClientActions.UNMUTE:
      client.unmuteAudio();
      break;
    case ATClientActions.HOLD:
      client.hold();
      break;
    case ATClientActions.UN_HOLD:
      client.unhold();
      break;
    default:
      throw new Error('Unsupported action');
  }
}
