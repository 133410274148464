import React from 'react';
import PropTypes from 'prop-types';

import TimeUtil from '../../Utils/Time';
import Avatar from '../../Blocks/Avatar';

const BroadcastMessageEntry = ({
  broadcastId,
  recipients,
  message,
  onSelect,
  isActive,
}) => {
  const { firstName } = recipients[0].name;
  const viewMessage = () => onSelect({ broadcastId });

  const getMessageEntryName = () => {
    const additional = recipients.length - 2;
    const leadingNames = recipients
      .slice(0, 2)
      .map(p => `${p.name.firstName} ${p.name.lastName}`)
      .join(', ');
    return (
      <div className="text-sm">
        <span className="font-weight-normal">
          {' '}
          {leadingNames} {recipients.length > 2 && '... '}
        </span>{' '}
        {recipients.length > 2 && (
          <span className="text-sm font-italic">
            {' '}
            +{additional} other {additional > 1 ? 'patients' : 'patient'}
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      className={
        isActive
          ? 'p-2 w-100 consultation-list-box active'
          : 'p-2 w-100 consultation-list-box'
      }
      onClick={viewMessage}
    >
      <div className={'consultation-chat-list-col1 m-auto'}>
        <Avatar letter={firstName.charAt(0)} />
      </div>

      <div className={'consultation-chat-list-col2'}>
        {getMessageEntryName()}
        {message && message.content && (
          <div className={'chat-last-message'}>{message.content.message}</div>
        )}
      </div>

      {message && (
        <div className={'consultation-chat-list-col3'}>
          {TimeUtil.displayTime(message.sent_ts)}
        </div>
      )}
    </div>
  );
};

BroadcastMessageEntry.defaultProps = {
  status: null,
  isActive: false,
  recipients: [],
};

BroadcastMessageEntry.propTypes = {
  broadcastId: PropTypes.string.isRequired,
  message: PropTypes.shape({
    consultation_id: PropTypes.string,
    content: PropTypes.shape({
      message: PropTypes.string,
    }).isRequired,
    sent_ts: PropTypes.string,
  }).isRequired,
  isActive: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  recipients: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    name: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export default BroadcastMessageEntry;
