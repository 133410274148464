import Keycloak from 'keycloak-js';
import { KEYCLOAK_CONFIG_FILE } from '../Constants';
import {
  getOrganizationDetails,
  getPractitionerDetails,
} from '../Services/adminModuleService';

const _kc = new Keycloak(KEYCLOAK_CONFIG_FILE);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param setUserIsAuthenticated
 */
const initKeycloak = setUserIsAuthenticated => {
  return _kc
    .init({
      onLoad: 'login-required',
    })
    .then(authenticated => {
      if (authenticated) {
        sessionStorage.setItem('access_token', `Bearer ${_kc.token}`);
        const practitionerId = _kc.tokenParsed.practitioner_id;
        sessionStorage.setItem('practitioner_id', practitionerId);
        Promise.all([
          getPractitionerDetails(practitionerId),
          getOrganizationDetails(practitionerId),
        ]).then(([prac, org]) => {
          sessionStorage.setItem('organization_id', org.id);
          sessionStorage.setItem('organization_name', org.name);
          sessionStorage.setItem('organization_is_payable', org.payable);
          sessionStorage.setItem(
            'name',
            `${prac.name.prefix} ${prac.name.firstName} ${prac.name.lastName}`
          );
          setUserIsAuthenticated(authenticated);
        });
        return setupTokenRefresher();
      } else {
        doLogin();
      }
    });
};

const getKeycloak = () => _kc;

const doLogin = () => _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const getTokenParsed = () => _kc.tokenParsed;

const isLoggedIn = () => !!_kc.token;

const setupTokenRefresher = () => {
  const duration = Math.round(
    _kc.tokenParsed.exp + _kc.timeSkew - new Date().getTime() / 1000
  );
  console.log(`Token valid for ${duration} seconds.`);
  return setInterval(() => {
    _kc
      .updateToken()
      .then(refreshed => {
        if (refreshed) {
          sessionStorage.setItem('access_token', `Bearer ${_kc.token}`);
          console.log('Token refreshed', refreshed);
        } else {
          console.warn(`Token not refreshed, valid for ${duration} seconds`);
        }
      })
      .catch(error => {
        console.error('Failed to refresh token: ', error);
      });
  }, duration * 1000);
};

const fetchAuthInfo = async () => {
  const token = _kc.tokenParsed;
  if (!token) return null;
  const practitionerId = token.practitioner_id;

  const [practitioner, organization] = await Promise.all([
    getPractitionerDetails(practitionerId),
    getOrganizationDetails(practitionerId),
  ]);

  return { practitioner, organization };
};

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = roles => roles.some(role => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getUsername,
  hasRole,
  getKeycloak,
  getTokenParsed,
  setupTokenRefresher,
  fetchAuthInfo,
};

export default UserService;
