import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import PaymentModal from '../Consultations/ConsultationPaymentModal';
import Avatar from '../../Blocks/Avatar';
import ActionButton from '../../Blocks/ActionButton';
import StartConsultationModal from './StartConsultationModal';
import PaymentHistory from '../Consultations/PaymentHistory';
import ConsultationCloseModal from '../Consultations/ConsultationCloseModal';
import ConfirmationModal from '../../Blocks/ConfirmationModal';
import { endConsultation } from '../../Services/backendService';

import CalendarIcon from '../../Assets/calendar.svg';
import MobilePayment from '../../Assets/mobile-payment.svg';
import DoctorIcon from '../../Assets/doctor.svg';
import ClockIcon from '../../Assets/clock.svg';
import RestartIcon from '../../Assets/restart.svg';
import GenderIcon from '../../Assets/gender.svg';
import TelephoneIcon from '../../Assets/telephone.svg';
import { AppContext } from '../../AppContext';
function ChatHeader({
  patient,
  consultation,
  canStartConsultation,
  startConsultation,
  restartConsultation,
  requestPayment,
}) {
  const canRequestPayments = sessionStorage.getItem('organization_is_payable') === 'true';

  const { showCallInterface, initiateCallRef } = useContext(AppContext);

  const [showStartConsultModal, setShowStartConsultModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showPaymentHistory, setShowPaymentHistory] = useState(false);
  const [showConsultationCloseModal, setShowConsultationCloseModal] = useState(false);
  const [showNotifyPatientModal, setShowNotifyPatientModal] = useState(false);
  const [consultationSummary, setConsultationSummary] = useState(null);
  const [showConsultationCloseError, setShowConsultationCloseError] = useState(false);

  const onStartConsultationClick = () => {
    setShowStartConsultModal(true);
  };

  const toggleConsultationModal = () => {
    setShowStartConsultModal(!showStartConsultModal);
  };

  const togglePaymentModal = () => {
    setShowPaymentModal(!showPaymentModal);
  };

  const startConsultationConfirmation = notes => {
    setShowStartConsultModal(false);
    startConsultation(notes);
  };

  const toggleMoreOptions = () => {
    setShowOptions(prev => !prev);
  };

  const togglePaymentHistory = () => {
    setShowPaymentHistory(prev => !prev);
  };

  const toggleConsultationClose = () => {
    setShowConsultationCloseModal(!showConsultationCloseModal);
  };

  const toggleNotifyPatientModal = () => {
    setShowNotifyPatientModal(!showNotifyPatientModal);
  };

  const toggleConsultationCloseError = () => {
    setShowConsultationCloseError(prev => !prev);
  };

  const submitRequestPayment = paymentDetails => {
    setShowPaymentModal(false);
    requestPayment(paymentDetails);
  };

  const onConsultationSummary = summary => {
    setConsultationSummary(summary);
    setShowNotifyPatientModal(true);
  };

  const onConfirm = notifyPatient => {
    toggleNotifyPatientModal();
    toggleConsultationClose();
    const payload = {
      ...consultationSummary,
      notifyPatient,
      patientId: patient.id,
      consultationId: consultation._id,
    };
    endConsultation(payload).then(res => {
      if (res && res.error) {
        if (res.error.includes('payment is pending')) {
          setShowConsultationCloseError(true);
        }
      }
    });
  };

  const onConsultationErrorConfirm = cancelPayment => {
    if (cancelPayment) {
      togglePaymentHistory();
    }
    toggleConsultationCloseError();
  };

  const onStartCall = () => {
    initiateCallRef.current({
      patient,
    });
  };

  const patientName = `${patient.name.firstName} ${patient.name.lastName}`;

  const availableActions = () => {
    if (!consultation && canStartConsultation && patient.active !== false) {
      return (
        <ActionButton
          label="Start Consultation"
          icon={DoctorIcon}
          clickHandler={onStartConsultationClick}
        />
      );
    }

    if (consultation) {
      return consultation.status === 'active' ? (
        <>
          <ActionButton
            label="End Consultation"
            icon={ClockIcon}
            clickHandler={toggleConsultationClose}
          />
          {canRequestPayments && (
            <ActionButton
              label="Request Payment"
              icon={MobilePayment}
              clickHandler={togglePaymentModal}
            />
          )}
        </>
      ) : (
        consultation.status === 'closed' && (
          <ActionButton
            label="Restart Consultation"
            icon={RestartIcon}
            clickHandler={restartConsultation}
          />
        )
      );
    }

    return null;
  };

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <>
      <div className="d-flex flex-row flex-shrink-0 bg-white justify-content-between m-1 shadow p-1">
        <div className="d-flex flex-row">
          <div className="p-2 m-auto">
            <Avatar letter={patient.name.firstName.charAt(0)} />
          </div>
          <div className="d-flex flex-column p-2">
            <h6>{patientName}</h6>
            <div className={isMobile ? 'd-flex flex-column' : 'd-flex flex-row my-auto'}>
              <div className="text-sm text-capitalize">
                {!isMobile && <img src={GenderIcon} alt="gender" className="m-1 w-1" />}
                {(patient.gender && patient.gender.toLowerCase()) || 'Unknown'}
              </div>
              <div className={isMobile ? 'text-sm mt-1' : 'ml-2 text-sm'}>
                {!isMobile && (
                  <img src={CalendarIcon} alt="date of birth" className="m-1 w-1" />
                )}
                {patient.birthDate || 'Unknown'}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row my-auto">
          {!showCallInterface && (
            <ActionButton
              label="Call Patient"
              icon={TelephoneIcon}
              clickHandler={onStartCall}
            />
          )}
          {availableActions()}
          {consultation && canRequestPayments && (
            <Dropdown
              className="button-reset"
              isOpen={showOptions}
              toggle={toggleMoreOptions}
            >
              <DropdownToggle className="button-reset options options-button"></DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={togglePaymentHistory}>
                  Payment History
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
      </div>
      <StartConsultationModal
        isOpen={showStartConsultModal}
        patientName={patientName}
        onConfirm={startConsultationConfirmation}
        toggle={toggleConsultationModal}
      />
      {consultation && (
        <>
          <PaymentModal
            consultationId={consultation._id}
            show={showPaymentModal}
            toggle={togglePaymentModal}
            requestPayment={submitRequestPayment}
          />
          <PaymentHistory
            show={showPaymentHistory}
            toggle={togglePaymentHistory}
            consultationId={consultation._id}
          />
          <ConsultationCloseModal
            toggle={toggleConsultationClose}
            show={showConsultationCloseModal}
            onSubmit={onConsultationSummary}
          />
          <ConfirmationModal
            toggle={toggleNotifyPatientModal}
            show={showNotifyPatientModal}
            message="Do you want to send this consultation summary to the patient?"
            onConfirm={onConfirm}
          />
          <ConfirmationModal
            show={showConsultationCloseError}
            toggle={toggleConsultationCloseError}
            message="Sorry, you can't close a consultation with an outstanding payment."
            onConfirm={onConsultationErrorConfirm}
            confirmLabel="Cancel Payment"
            declineLabel="OK"
          />
        </>
      )}
    </>
  );
}

ChatHeader.defaultProps = {
  isConsultation: false,
};

ChatHeader.propTypes = {
  patient: PropTypes.shape({
    name: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    birthDate: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  canStartConsultation: PropTypes.bool.isRequired,
  startConsultation: PropTypes.func.isRequired,
  restartConsultation: PropTypes.func.isRequired,
  requestPayment: PropTypes.func.isRequired,
};

export default ChatHeader;
