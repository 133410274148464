import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'reactstrap';

import ChatBox from './ChatBox';
import ChatWrite from './ChatWrite';
import ChatTimer from '../Consultations/ConsultationTimer';
import ChatHeader from './ChatHeader';

const ChatView = ({
  consultation,
  patient,
  messages,
  practitioners,
  recipients,
  isBroadcast,
  sendMessage,
  openConsultation,
  startConsultation,
  restartConsultation,
  goToOpenConsultation,
  requestPayment,
}) => {
  const chatBoxArea = createRef();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const loggedInPractitioner = sessionStorage.getItem('practitioner_id');

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const chatDisabled = consultation && consultation.status === 'closed';
  const lastPatientMessage = isBroadcast
    ? null
    : (() => {
        for (let i = messages.length - 1; i >= 0; i--) {
          if (messages[i].from === patient.id) {
            return messages[i];
          }
        }
        return null;
      })();

  const patientNames = recipients
    .slice(0, 4)
    .map(p => `${p.name.firstName} ${p.name.lastName}`)
    .join(', ');

  const getTextArea = () => {
    if (isBroadcast && messages.length) {
      return null;
    }

    if (!consultation && openConsultation) {
      return (
        <div className="w-100 m-0 p-4 mb-1 d-flex flex-column text-center">
          <p className="font-weight-normal">
            Patient has an active or pending consultation.
          </p>
          {loggedInPractitioner ===
            openConsultation.consultation_accepted.practitioner_id && (
            <button
              className="button-reset mx-auto px-2 py-1 text-brand-secondary"
              onClick={goToOpenConsultation}
            >
              Go to consultation
            </button>
          )}
        </div>
      );
    }

    return (
      <ChatWrite isDisabled={chatDisabled} sendMessage={sendMessage} patient={patient} />
    );
  };

  return (
    <>
      {consultation &&
        lastPatientMessage &&
        lastPatientMessage.channel === 'whatsapp' && (
          <ChatTimer
            lastPatientMessage={lastPatientMessage}
            consultationStatus={consultation.status}
          />
        )}
      <div className="d-flex flex-column h-90">
        {isBroadcast ? (
          <div className="d-flex flex-row bg-white justify-content-between m-1 shadow p-1">
            <div className="d-flex flex-row">
              <div className="d-flex flex-column p-2">
                <div className="px-2" id="patient-names">
                  <h6>
                    {patientNames} {recipients.length > 4 && '...'}
                  </h6>
                  <span className="font-italic">
                    SMS broadcast to {recipients.length} patients.
                  </span>
                </div>
                <Tooltip
                  placement="bottom"
                  isOpen={tooltipOpen}
                  target="patient-names"
                  toggle={toggle}
                >
                  {recipients
                    .map(p => `${p.name.firstName} ${p.name.lastName}`)
                    .join(', ')}
                </Tooltip>
              </div>
            </div>
          </div>
        ) : (
          <ChatHeader
            patient={patient}
            consultation={consultation}
            canStartConsultation={!openConsultation}
            restartConsultation={restartConsultation}
            startConsultation={startConsultation}
            requestPayment={requestPayment}
          />
        )}

        <ChatBox
          ref={chatBoxArea}
          patient={patient}
          messages={messages}
          practitioners={practitioners}
        />

        {getTextArea()}
      </div>
    </>
  );
};

ChatView.defaultProps = {
  consultation: null,
  recipients: [],
  isBroadcast: false,
};

ChatView.propTypes = {
  consultation: PropTypes.shape({
    _id: PropTypes.string,
    status: PropTypes.oneOf(['active', 'closed', 'pending_patient_approval']),
  }),
  patient: PropTypes.shape({
    name: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
  }),
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      consultation_id: PropTypes.string,
      content: PropTypes.shape({
        message: PropTypes.string,
      }).isRequired,
      sent_ts: PropTypes.string,
    })
  ).isRequired,
  practitioners: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.shape({
        prefix: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      id: PropTypes.string,
    })
  ).isRequired,
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }).isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
  isBroadcast: PropTypes.bool,
  sendMessage: PropTypes.func.isRequired,
  openConsultation: PropTypes.shape({
    consultation_accepted: PropTypes.shape({
      practitioner_id: PropTypes.string,
    }),
  }).isRequired,
  startConsultation: PropTypes.func.isRequired,
  restartConsultation: PropTypes.func.isRequired,
  goToOpenConsultation: PropTypes.func.isRequired,
  requestPayment: PropTypes.func.isRequired,
};

export default ChatView;
