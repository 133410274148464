import React, { useState, useEffect } from 'react';

import {
  Row,
  Col,
  Input,
  ListGroup,
  ListGroupItem,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import Avatar from '../../Blocks/Avatar';
import { SearchOutlined } from '@ant-design/icons';
import PatientDetails from './PatientDetails';
import PatientCreateModal from './PatientCreateModal';
import { filterPatientByName } from '../../Utils/search';

const PatientList = ({ patients }) => {
  const [activePatient, setActivePatient] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [showDetails, setshowDetails] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    const patientData = filterPatientByName(patients, searchValue);
    setPatientList(patientData);
  }, [searchValue]);

  useEffect(() => {
    setPatientList(patients);
  }, [patients]);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const toggleDetails = () => {
    if (showDetails) {
      setActivePatient(null);
    }
    setshowDetails(!showDetails);
  };

  const setActive = item => {
    setActivePatient(item);
    if (isMobile) {
      toggleDetails();
    }
  };

  const onPatientCreate = patient => {
    const updatedPatientList = [...patientList, patient].sort((a, b) => {
      if (a.name.firstName < b.name.firstName) {
        return -1;
      }
      if (a.name.firstName > b.name.firstName) {
        return 1;
      }
      return a.name.lastName < b.name.lastName ? -1 : 1;
    });
    setPatientList(updatedPatientList);
    setActivePatient(patient);
  };

  const togglePatientCreate = () => {
    setShowCreateModal(!showCreateModal);
  };

  const listItems = patientList.map(patient => (
    <div
      key={patient.id}
      className={
        activePatient && activePatient.id === patient.id
          ? 'border-0 rounded-0 active-patient'
          : 'border-bottom rounded-0'
      }
      onClick={() => setActive(patient)}
    >
      <div className="d-flex flex-row align-items-center px-4 py-2 ">
        <Avatar letter={patient.name.firstName.charAt(0)} />
        <p className="my-auto pl-3">
          {patient.name.firstName} {patient.name.lastName}
        </p>
      </div>
    </div>
  ));

  return (
    <>
      <MediaQuery minWidth={768}>
        <Row className="m-0 p-0" style={{ height: '-webkit-fill-available' }}>
          <Col xs="4" className="m-0 p-0 patient-list-column">
            <InputGroup className="mt-3 mb-2 pl-3">
              <Input
                placeholder="Search for Patients"
                className={'search-input'}
                onKeyUp={event => setSearchValue(event.currentTarget.value)}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText className={'search-group'}>
                  <SearchOutlined className={'search-icon'} />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            <div className="m-0 px-2 py-1 w-100 d-flex justify-content-end">
              {/* <button
                className="button-reset p-1 text-brand-secondary"
                onClick={togglePatientCreate}
              >
                + Add New Patient
              </button> */}
            </div>
            <ListGroup className="patient-list-container">{listItems}</ListGroup>
          </Col>
          <Col className="patient-details-container m-0 p-2">
            {activePatient && <PatientDetails activePatient={activePatient} />}
          </Col>
        </Row>
      </MediaQuery>
      <MediaQuery maxWidth={767}>
        <Row className="m-0 p-0 bg-white" style={{ height: '-webkit-fill-available' }}>
          <Col
            xs="12"
            className={
              showDetails ? 'm-0 p-0 hide-patient-list' : 'm-0 p-0 show-patient-list'
            }
          >
            <InputGroup className="mt-3 mb-2 pl-3">
              <Input
                placeholder="Search for Patients"
                className={'search-input'}
                onKeyDown={event => setSearchValue(event.currentTarget.value)}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText className={'search-group'}>
                  <SearchOutlined className={'search-icon'} />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            <div className="m-0 px-2 py-1 w-100 d-flex justify-content-end">
              {/* <button
                className="button-reset p-1 text-brand-secondary"
                onClick={togglePatientCreate}
              >
                + Add New Patient
              </button> */}
            </div>
            <ListGroup className="patient-list-container">{listItems}</ListGroup>
          </Col>
          {activePatient && (
            <Col xs="12" className="patient-details-container">
              <PatientDetails
                activePatient={activePatient}
                showDetails={showDetails}
                toggleDetails={toggleDetails}
              />
            </Col>
          )}
        </Row>
      </MediaQuery>
      <PatientCreateModal
        show={showCreateModal}
        toggle={togglePatientCreate}
        onPatientCreate={onPatientCreate}
      ></PatientCreateModal>
    </>
  );
};

export default PatientList;
