import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

import Avatar from '../../Blocks/Avatar';
import CalendarIcon from '../../Assets/calendar.svg';
import GenderIcon from '../../Assets/gender.svg';
import { SearchOutlined } from '@ant-design/icons';
import { filterPatientByName } from '../../Utils/search';

const PatientsModal = ({ patients, show, toggle, onSelect }) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedPatients, setSelectedPatients] = useState({});
  const [selectedCount, setSelectedCount] = useState(0);
  const [filteredPatients, setFilteredPatients] = useState([]);

  useEffect(() => {
    setFilteredPatients(patients);
  }, [patients]);

  const onValueChange = (evt, id) => {
    const value = evt.target.checked;
    const count = value ? selectedCount + 1 : selectedCount - 1;
    setSelectedCount(count);
    setSelectedPatients({ ...selectedPatients, [id]: value });
    setSelectAll(false);
  };

  const onSelectAll = evt => {
    const val = evt.target.checked;
    let selected = { ...selectedPatients };
    let count = selectedCount;
    if (val) {
      // Reset
      patients.forEach(p => {
        selected[p.id] = false;
      });
      // Select all from filtered list
      filteredPatients.forEach(p => {
        selected[p.id] = true;
      });
      count = filteredPatients.length;
    } else {
      filteredPatients.forEach(p => {
        selected[p.id] = false;
      });
      count = 0;
    }
    setSelectedCount(count);
    setSelectedPatients(selected);
    setSelectAll(val);
  };

  const selectPatient = pId => {
    const selected = { ...selectedPatients };
    let count = selectedCount;
    if (!selected[pId]) {
      selected[pId] = true;
      count += 1;
    } else {
      selected[pId] = false;
      count -= 1;
    }
    setSelectedCount(count);
    setSelectAll(false);
    setSelectedPatients(selected);
  };

  const clearSelection = () => {
    const selected = [...filteredPatients];
    selected.forEach(p => {
      selected[p.id] = false;
    });
    setSelectedCount(0);
    setSelectAll(false);
    setSelectedPatients(selected);
  };

  const submitSelected = () => {
    onSelect(patients.filter(p => selectedPatients[p.id]));
    setSelectedCount(0);
    setSelectAll(false);
    setSelectedPatients({});
  };

  const filterPatients = evt => {
    const searchKey = (evt.target.value || '').trim();
    if (searchKey) {
      setFilteredPatients(filterPatientByName(patients, searchKey));
    } else {
      setFilteredPatients(patients);
      setSelectAll(patients.every(p => selectedPatients[p.id]));
    }
  };

  return (
    <Modal isOpen={show} toggle={toggle} scrollable={true}>
      <ModalHeader toggle={toggle}>Select a patient(s) to message.</ModalHeader>
      <ModalBody>
        <div className="d-flex flex-row">
          <div className="d-flex flex-row flex-grow-1">
            <div className="form-group form-check m-0 my-auto">
              <input
                type="checkbox"
                className="form-check-input"
                id="select-all"
                checked={selectAll}
                onChange={onSelectAll}
              ></input>
              <label className="form-check-label" htmlFor="select-all">
                Select All
              </label>
            </div>

            <button
              className="btn btn-light btn-sm text-secondary mx-2"
              onClick={clearSelection}
            >
              Clear Selection
            </button>
          </div>
          <div className="pt-1 text-sm">
            {selectedCount ? (
              <p className="m-0 p-0">{selectedCount} patient(s) selected.</p>
            ) : (
              <p className="m-0 p-0">No patients selected.</p>
            )}
          </div>
        </div>
        <div>
          <InputGroup className="my-2 pl-2">
            <Input
              placeholder="Search for patients"
              className="search-input"
              onKeyUp={filterPatients}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText className={'search-group'}>
                <SearchOutlined className={'search-icon'} />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
        {filteredPatients.map(p => (
          <div
            className="mx-auto px-2 py-2 w-md-75 w-sm-100 d-flex flex-row bg-light mb-2"
            key={p.id}
            onClick={() => selectPatient(p.id)}
          >
            <div className="my-auto px-2">
              <input
                type="checkbox"
                checked={selectedPatients[p.id]}
                onChange={e => onValueChange(e, p.id)}
              ></input>
            </div>
            <div className="my-auto">
              <Avatar letter={p.name.firstName.charAt(0)} />
            </div>

            <div className="flex-grow text-left chat-name my-auto pl-4 position-relative w-100">
              <p className="m-0 text-body">
                {p.name.firstName} {p.name.lastName}
              </p>
              <div className="d-flex flex-row">
                <div className="text-sm text-capitalize" style={{ width: '33.3%' }}>
                  <img src={GenderIcon} alt="gender" className="m-1 w-1" />
                  {(p.gender && p.gender.toLowerCase()) || 'Unknown'}
                </div>
                <div className="text-sm ml-2">
                  <img src={CalendarIcon} alt="date of birth" className="m-1 w-1" />
                  {p.birthDate || 'Unknown'}
                </div>
              </div>
            </div>
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-primary btn-sm"
          onClick={submitSelected}
          disabled={selectedCount === 0}
        >
          Message Patients
        </button>
      </ModalFooter>
    </Modal>
  );
};

PatientsModal.defaultProps = {
  patients: [],
};

PatientsModal.propTypes = {
  patients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default PatientsModal;
