import React, { useContext, useState } from 'react';
import { Button } from 'reactstrap';
import parsePhoneNumber from 'libphonenumber-js';

import { AppContext } from '../../AppContext';
import { CallType } from '../../Services/voiceService';

const TryCalling = () => {
  const [testNumber, setTestNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  const { showCallInterface, initiateCallRef } = useContext(AppContext);
  const onStartCall = () => {
    if (testNumber.length > 0) {
      if (!showCallInterface) {
        initiateCallRef.current({
          type: CallType.OUTGOING,
          duration: 0,
          onHold: false,
          isMuted: false,
          patient: {
            name: {
              firstName: 'trial',
              lastName: 'call',
            },
            id: '',
            phoneNumber: [parsePhoneNumber(testNumber, 'KE').number],
          },
        });
      }
    } else {
      setError(true);
      setErrorMessage('Please enter a valid number');
    }
  };

  const clearError = () => {
    setError(false);
    setErrorMessage('');
  };

  return (
    <div className="mt-5">
      <div className="d-flex flex-row">
        <input
          className="callInput"
          placeholder="0712345678"
          onChange={event => setTestNumber(event.target.value)}
          onFocus={clearError}
        />
        <Button className="callButton" onClick={onStartCall}>
          {' '}
          Call
        </Button>
      </div>
      {error ? <p className="validation-error-text">{errorMessage}</p> : ''}
      <p className="description">
        Enter any phone number to call <br />
        (except the one connected to this <br />
        device). You can try calling a <br />
        patient, or yourself!
      </p>
    </div>
  );
};

export default TryCalling;
