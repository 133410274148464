// Module for declaring simple reusable utility functions

export function sortPatientsByNames(patientA, patientB) {
  if (patientA.name.firstName < patientB.name.firstName) {
    return -1;
  }
  if (patientA.name.firstName > patientB.name.firstName) {
    return 1;
  }
  return patientA.name.lastName < patientB.name.lastName ? -1 : 1;
}
