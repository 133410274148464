import React, { useContext } from 'react';
import { Row } from 'reactstrap';
import moment from 'moment';
import classNames from 'classnames';
import CalenderIcon from '../../Assets/calendar.svg';
import DoctorIcon from '../../Assets/doctor.svg';
import PatientAvatar from '../../Blocks/PatientAvatar';
import { AppContext } from '../../AppContext';

const PatientSummary = ({ details, view }) => {
  const { initiateCallRef } = useContext(AppContext);
  const { name, phoneNumber, birthDate, gender, address } = details;
  const age = moment().diff(moment(birthDate, 'DD/MM/YYYY'), 'years');

  const onStartCall = () => {
    initiateCallRef.current({
      patient: details,
    });
  };

  const patientLocation = () => {
    const PatientAddress = address || {};
    if (PatientAddress.town && PatientAddress.city) {
      return `${PatientAddress.town}, ${PatientAddress.city}`;
    } else if (PatientAddress.town) {
      return PatientAddress.town;
    } else if (PatientAddress.city) {
      return PatientAddress.city;
    }
    return 'Not provided';
  };

  return (
    <>
      <div
        className={classNames(
          'px-2 pt-3 pb-4 mb-2 d-flex flex-column justify-content-start'
        )}
      >
        <Row
          className={classNames(' p-0 d-flex justify-content-between', {
            'w-100': view === 'practitioner',
            'w-75': view === 'patient',
          })}
          style={{ height: '-webkit-fill-available', margin: '0 auto' }}
        >
          <div className=" d-flex flex-row">
            <div className="mt-5 d-flex flex-column">
              <PatientAvatar letter={name.firstName.charAt(0)} />
            </div>
            <div className="mt-2 d-flex flex-column" style={{ marginLeft: '5rem' }}>
              <h3 style={{ fontStyle: 'bold' }}>
                {name.firstName} {name.lastName}
              </h3>
              <span className="mt-1">
                Phone Number:{' '}
                <button className="call-button" onClick={onStartCall}>
                  {phoneNumber[0]} {phoneNumber.length > 2 && '...'}
                </button>
              </span>
              <span className="mt-1">Age: {age || 'Not provided'}</span>
              <span className="mt-1">Gender: {gender || 'Not provided'}</span>
              <span className="mt-1">
                Location: {patientLocation() || 'Not provided'}{' '}
              </span>
            </div>
          </div>

          <div
            className={classNames('mt-4 d-flex flex-column', {
              'button-display-show': view === 'practitioner',
              'button-display-hide': view === 'patient',
            })}
          >
            <button className="patient-portal-button" style={{ display: 'none' }}>
              <img className=" w-1 mr-2" src={DoctorIcon} alt="Consultation" />
              New consultation
            </button>
            <button className="patient-portal-button mt-1" style={{ display: 'none' }}>
              <img className="w-1 mr-2" src={CalenderIcon} alt="Appointment" />
              New Appointment
            </button>
          </div>
        </Row>
      </div>
    </>
  );
};

export default PatientSummary;
