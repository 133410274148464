import React, { useState, useEffect } from 'react';
import { Button, Form, Select, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const LocationForm = props => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [location, setLocation] = useState('');
  const [coordinates, setCoordinates] = useState({ lat: -1.2884, lng: 36.8233 });

  const validateInput = values => {
    if (values.option === 'Map' && coordinates) {
      props.setLocationValues({ option: values.option, coordinates });
      props.handleNext();
    }
    if (values.option === 'Skip') {
      props.setLocationValues({
        option: values.option,
        coordinates: { lat: 0, lng: 0 },
      });
      props.handleNext();
    }
  };

  const handleSelect = value => {
    if (value === 'Skip') {
      setLocation('Skip');
    }
    if (value === 'Map') {
      setLocation('Map');
    }
  };

  const handleBack = () => {
    props.handleBack();
  };

  const onMarkerDragEnd = (props, marker, e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setCoordinates({ lat, lng });
  };

  useEffect(() => {
    const { option } = props;
    form.setFieldsValue({
      option: option,
    });
    setLocation(option);
  }, []);

  useEffect(() => {
    if (props.coordinates) {
      setCoordinates({ lat: props.coordinates.lat, lng: props.coordinates.lng });
    } else if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(pos => {
        const coords = pos.coords;
        setCoordinates({ lat: coords.latitude, lng: coords.longitude });
      });
    } else {
      setCoordinates({ lat: -1.2884, lng: 36.8233 });
    }
  }, []);

  const mapStyles = {
    width: '100%',
    height: '100%',
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 12,
    },
  };

  const mapLayout = {
    wrapperCol: {
      span: 24,
    },
  };

  const tailLayout = {
    wrapperCol: {
      span: 24,
    },
  };

  return (
    <>
      <Form
        layout={'horizontal'}
        {...layout}
        size={'default'}
        onFinish={validateInput}
        form={form}
      >
        <Form.Item
          label="Select Location"
          name="option"
          tooltip={{
            title: 'You can skip this request by responding with "Skip"',
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: 'Please Select a location or skip.',
            },
          ]}
        >
          <Select placeholder="Select your Location" onChange={handleSelect}>
            <Option value="Skip">Skip</Option>
            <Option value="Map">Select from map</Option>
          </Select>
        </Form.Item>
        {location === 'Map' ? (
          <Form.Item className="map-container" {...mapLayout}>
            <Map
              google={props.google}
              zoom={15}
              style={mapStyles}
              initialCenter={coordinates}
            >
              <Marker draggable={true} onDragend={onMarkerDragEnd} />
            </Map>
          </Form.Item>
        ) : null}
        <Form.Item {...tailLayout}>
          <Space size={'large'}>
            <Button type="primary" htmlType="submit" onClick={() => handleBack()}>
              Back
            </Button>

            <Button type="primary" htmlType="submit">
              {location === 'Map' ? 'Select Location' : 'Next'}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default GoogleApiWrapper({ apiKey: 'AIzaSyBnRsSv_n_qyYlWOR7cMpBB0hoIWLOguLg' })(
  LocationForm
);
