import React from 'react';
import { useMediaQuery } from 'react-responsive';

const Hexagon = ({ color, boldText, lightText, size, name, leftText }) => {
  const styles = {
    marginleft: '0',
    '--background': color,
  };

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div>
      <span
        className={name ? name : isMobile ? 'hex-splash-small' : 'hex-splash'}
        style={styles}
      >
        <span style={{ color: '#ffffff' }}></span>
        <div
          className={
            leftText
              ? 'd-flex align-items-center small-text-container'
              : 'd-flex align-items-center large-text-container'
          }
        >
          {lightText ? (
            <p className="lightText">{lightText}</p>
          ) : (
            <p
              className={
                name ? 'hex-splash-p' : isMobile ? 'hex-splash-small-p' : 'hex-splash-p'
              }
            >
              {boldText}
            </p>
          )}
        </div>
      </span>
    </div>
  );
};

export default Hexagon;
