import FuzzySearch from 'fuzzy-search';

export const filterPatientByName = (patients, searchTerm) => {
  if (Array.isArray(patients)) {
    const searcher = new FuzzySearch(patients, ['name.firstName', 'name.lastName'], {
      caseSensitive: false,
    });
    return searcher.search(searchTerm);
  }
  return [];
};
