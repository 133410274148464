import io from 'socket.io-client';
import { SOCKET_URL } from '../Constants';

const WebsocketUtil = {
  getSocket: () => {
    const accessToken = sessionStorage.getItem('access_token');
    const socket = io.connect(SOCKET_URL, {
      path: '/mbalihealth/socket.io',
      query: `token=${accessToken.replace('Bearer ', '')}`,
    });

    socket.emit('join', {
      uid: sessionStorage.getItem('practitioner_id'),
      organizationId: sessionStorage.getItem('organization_id'),
    });
    return socket;
  },

  leave: (socket, room) => {
    console.log(`Leaving consultation ${room}`);
    socket.emit('leave', {
      room,
      practitionerId: sessionStorage.getItem('practitioner_id'),
    });
  },

  send: (socket, message) => {
    socket.emit('message', message);
  },

  getConsultationMessages: (socket, message) => {
    socket.emit('consultation_messages', message);
  },

  getPatientOrganizationMessages: (socket, message) => {
    socket.emit('get_patient_org_msgs', message);
  },

  onOrganizationMessage: (socket, cb) => {
    socket.on('org_single_message', cb);
  },

  onOrganizationMessages: (socket, cb) => {
    socket.on('org_messages', cb);
  },

  onPatientOrganizationMessages: (socket, cb) => {
    socket.on('patient_org_messages', cb);
  },

  onBroadcastMessage: (socket, cb) => {
    socket.on('org_broadcast_message', cb);
  },

  onConsultationUpdate: (socket, cb) => {
    socket.on('consultation_message', cb);
  },

  onConsultations: (socket, cb) => {
    socket.on('consultations', cb);
  },

  onConsultationMessages: (socket, cb) => {
    socket.on('consultation_messages', cb);
  },

  onConsultationStart: (socket, cb) => {
    socket.on('consultation_start', cb);
  },

  onConsultationEnd: (socket, cb) => {
    socket.on('consultation_end', cb);
  },

  removeMessageListener: socket => {
    console.log('remove message listener');
    socket.off('message');
  },

  addOnDisconnectListener: (socket, cb) => {
    socket.on('disconnect', cb);
  },

  addOnConnectListener: (socket, cb) => {
    socket.on('connect', cb);
  },

  addOnErrorListener: (socket, cb) => {
    socket.on('error', cb);
  },
};

export default WebsocketUtil;
