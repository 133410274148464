import React from 'react';
import Layout from '../Blocks/Layout';

function Help() {
  return (
    <Layout>
      <div className="help-container">
        <h3>Welcome to Mbali Health! </h3>
        <p>
          Mbali heath is a simple telemedicine service that allows you to receive virtual
          care from anywhere. It works by connecting you to your clinic via WhatsApp so
          you can ask questions, receive consultations and get expert medical advice. You
          can ask anything you want on Mbali Health, but here are a few suggestions on how
          you can use our service:
        </p>
        <ol type="1">
          <li>
            Talk to a clinician before you go to the facility so you can skip the lines
            and get the services you need without needing a consultation
          </li>
          <li> Book an appointment</li>
          <li> Use it for a second opinion on medical advice you received elsewhere</li>
          <li> Ask about the availability of services</li>
          <li> Enquire about medication stock for a refill</li>
          <li>
            Have medication delivered to your home (this service is not available at all
            clinics)
          </li>
          <li> Keep social/physical distance due to the COVID-19 pandemic</li>
        </ol>

        <h3>How does it work?</h3>
        <p>
          Mbali Health connects you to healthcare professionals through WhatsApp. Here’s
          how the process works:
        </p>
        <ol>
          <li>
            Sign up: When you message our WhatsApp number (+254 20 3893148), you start the
            sign-up process, where we will ask you for some basic information about
            yourself such as your name, date of birth, and gender.{' '}
          </li>

          <li>
            Tell us about why you’re reaching out: After you have signed up, you can ask
            any questions you want to the clinic you’ve signed up with.
          </li>

          <li>
            Chat with a clinician: Your message is delivered to the clinic, where a
            clinician or staff member will answer it as soon as they are able.
          </li>
        </ol>

        <h3>FAQ:</h3>
        <ul>
          <li>Is Mbali Health Free? </li>
          <ul>
            <li>
              It depends. Your clinician may request payment for a consultation, but
              that’s up to them. If they don’t then Mbali Health is totally free{' '}
            </li>
          </ul>

          <li>Can I call the Mbali Health number? </li>
          <ul>
            <li>
              No. Unfortunately, calling isn’t yet enabled in Mbali Health. It’s on our of
              features to develop, but it’s not there yet{' '}
            </li>
          </ul>
          <li>Can I send pictures or videos? </li>
          <ul>
            <li>
              No. We’re working on adding this functionality, but for now, if you send a
              picture or video, we wont’ receive it.
            </li>
          </ul>
        </ul>
      </div>
    </Layout>
  );
}

export default Help;
