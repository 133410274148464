import React, { useState } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { AppContext } from '../AppContext';
import Avatar from './Avatar';

import logo from '../Assets/ilara-white-icon.svg';
import ilaraLogo from '../Assets/ilara-logo-white.svg';

const Header = () => {
  const { userIsAuthenticated, logoutRef } = React.useContext(AppContext);

  const onLogout = logoutRef.current;

  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const practitionerName = sessionStorage.getItem('name') || '';
  const toggleNav = () => setIsOpen(!isOpen);
  const toggleSettings = () => setDropdownOpen(prevState => !prevState);

  return (
    <Navbar fixed={'top'} color="dark" dark expand="md">
      <NavbarBrand
        tag={RRNavLink}
        exact
        to="/"
        activeClassName=""
        className="custom-icon-name"
      >
        {userIsAuthenticated ? (
          <>
            <img
              src={logo}
              style={{ width: '40px', maxHeight: '40px', marginTop: '-7px' }}
              alt="logo"
            />
            <p>{sessionStorage.getItem('organization_name')}</p>
          </>
        ) : (
          <img src={ilaraLogo} style={{ width: '110px', marginTop: '-7px' }} alt="logo" />
        )}
      </NavbarBrand>
      <NavbarToggler onClick={toggleNav} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          {userIsAuthenticated ? (
            <>
              <NavItem>
                <NavLink tag={RRNavLink} exact to="/" activeClassName="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="my-auto w-2 px-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                  </svg>
                  Messaging
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={RRNavLink} exact to="/patients" activeClassName="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="my-auto w-2 px-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                  </svg>
                  Patients
                </NavLink>
              </NavItem>
            </>
          ) : (
            <></>
          )}
          <NavItem>
            <div className="ml-md-4 d-flex flex-row cursor-pointer">
              <Avatar
                letter={
                  practitionerName &&
                  practitionerName.split(' ')[1].charAt(0).toUpperCase()
                }
              />
              <p className="my-auto mx-2 text-white">{practitionerName}</p>
              <Dropdown isOpen={dropdownOpen} toggle={toggleSettings} right>
                <DropdownToggle className="dropdown-button">
                  {dropdownOpen ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  )}
                </DropdownToggle>
                {userIsAuthenticated ? (
                  <DropdownMenu right>
                    <DropdownItem href="/clinic/help">Help Page</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href="/clinic/privacy">Privacy Policy</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={onLogout}>Logout</DropdownItem>
                  </DropdownMenu>
                ) : (
                  <DropdownMenu right>
                    <DropdownItem href="/help">Help Page</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href="/privacy">Privacy Policy</DropdownItem>
                  </DropdownMenu>
                )}
              </Dropdown>
            </div>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Header;
