import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { PaymentLabel } from '../../Constants';
import {
  cancelPaymentRequest,
  getPaymentRequests,
  requestPayment,
} from '../../Services/backendService';

function PaymentHistory({ consultationId, show, toggle }) {
  const [paymentRequests, setPaymentRequests] = useState([]);

  useEffect(() => {
    if (show) {
      getPaymentRequests(consultationId).then(requests => {
        setPaymentRequests(requests);
      });
    }
  }, [show, consultationId]);

  const getLabelDisplayName = label => {
    switch (label) {
      case PaymentLabel.CONSULTATION:
        return 'Consultation';
      case PaymentLabel.LAB_TEST:
        return 'Lab Test';
      case PaymentLabel.MEDICATION:
        return 'Medication';
      default:
        return 'Payment Request';
    }
  };

  const fetchPaymentRequest = () => {
    getPaymentRequests(consultationId).then(requests => {
      setPaymentRequests(requests);
    });
  };

  const cancelPayment = requestId => {
    cancelPaymentRequest(consultationId, requestId).then(() => {
      fetchPaymentRequest();
    });
  };

  const reRequestPayment = ({ amount, label, description }) => {
    requestPayment({ consultationId, amount, label, description }).then(() => {
      fetchPaymentRequest();
    });
  };

  const statusDisplay = status => {
    if (status === 'pending') {
      return <span className="text-secondary">Pending</span>;
    }

    if (status === 'paid') {
      return <span className="text-success">Successful</span>;
    }

    if (status === 'cancelled') {
      return <span className="text-muted">Cancelled</span>;
    }

    return null;
  };

  return (
    <>
      <Modal isOpen={show} toggle={toggle}>
        <ModalHeader toggle={toggle}>Payment History</ModalHeader>
        <ModalBody>
          {paymentRequests.map(req => (
            <div
              key={req._id}
              className="d-flex flex-row m-1 px-2 py-3 w-100 text-sm border-bottom"
            >
              <div className="w-75">
                <p className="m-0 mb-1 font-weight-bold">
                  {getLabelDisplayName(req.label)}
                </p>
                <p className="m-0 mb-1 text-capitalize">{req.description || '-'}</p>
                <p className="m-0 text-xs text-black-50">
                  {moment(req.created_timestamp).format('DD/MM/YYYY hh:mm A')}
                </p>
              </div>
              <div className="">
                <div className="font-weight-bold mb-1">{req.amount} KES</div>
                <div className="mb-1">{statusDisplay(req.status)}</div>
                {req.status === 'pending' ? (
                  <button
                    className="button-reset text-danger"
                    onClick={() => cancelPayment(req._id)}
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    className="button-reset text-secondary"
                    onClick={() => reRequestPayment(req)}
                  >
                    Request again
                  </button>
                )}
              </div>
            </div>
          ))}
          {paymentRequests.length === 0 && (
            <p className="text-center py-4">There are no requested payments.</p>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}

PaymentHistory.propTypes = {
  consultationId: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default PaymentHistory;
