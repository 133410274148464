import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Input, Select } from 'antd';
import countries from '../Utils/Countries';

function CountryPhoneInput({ onChange, value, selectProps, ...props }) {
  const defaultCountry = useMemo(() => {
    return countries.find(c => c.code === 'KE');
  }, []);

  const [country, setCountry] = useState(defaultCountry);
  const [phone, setPhone] = useState();

  useEffect(() => {
    if (value) {
      if (value.code) {
        setCountry(countries.find(c => c.code === value.code));
      } else {
        setCountry(countries.find(c => Number(c.phoneCode) === value.code));
      }
      setPhone(value.phone);
    }
  }, [value]);

  const triggerChange = useCallback(
    (phone, country) => {
      const result = {
        phone,
        phoneCode: country && Number(country.phoneCode),
        code: country && country.code,
      };
      onChange(result);
    },
    [onChange]
  );

  const handleCountryChange = useCallback(
    value => {
      const c = countries.find(c => c.code === value);
      if (!c) {
        throw new Error(`Country not found: ${value}`);
      }
      setCountry(c);
      triggerChange(phone, c);
    },
    [setCountry, triggerChange, phone]
  );

  const handlePhoneChange = useCallback(
    e => {
      const currentValue = e.target.value;
      setPhone(currentValue);
      triggerChange(currentValue, country);
    },
    [setPhone, country, triggerChange]
  );

  const select = (
    <Select
      bordered={true}
      dropdownMatchSelectWidth={false}
      {...selectProps}
      optionLabelProp="label"
      value={country && country.code}
      onChange={handleCountryChange}
    >
      {countries.map(item => {
        const fix = {
          key: item.code,
          value: item.code,
          label: `${item.phoneCode}`,
        };
        return (
          <Select.Option {...props.optionProps} {...fix}>
            {item.name} {item.phoneCode}
          </Select.Option>
        );
      })}
    </Select>
  );

  return (
    <Input
      addonBefore={select}
      {...props}
      value={value && value.phone}
      onChange={handlePhoneChange}
    />
  );
}

export { CountryPhoneInput };
export default CountryPhoneInput;
