import React from 'react';
import { Row, Container, Col } from 'reactstrap';

import Header from './Header';

const Layout = props => {
  return (
    <main className={'main'}>
      <Header keycloak={props.keycloak} />
      <Container fluid className={'h-100'}>
        <Row noGutters={true} className={'h-100'}>
          <Col sm={12} className={'content h-100'}>
            {props.children}
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default Layout;
