import React, { useState } from 'react';
import SingleHexagon from '../Compositions/SplashScreens/SingleHexagon';
import DoubleHexagon from '../Compositions/SplashScreens/DoubleHexagon';
import CallTutorial from '../Compositions/SplashScreens/CallTutorial';
import PatientCallTutorial from '../Compositions/SplashScreens/PatientCallTutorial';
import TryCalling from '../Compositions/SplashScreens/TryCalling';

const VoipSplashScreen = ({ toggleModal }) => {
  const toggle = step => setCurrentStep(step);

  const step1 = (
    <SingleHexagon
      color="#4CC8E7"
      name="hex-splash"
      boldText="Introducing Voice"
      next={() => toggle(step2)}
    />
  );
  const step2 = (
    <DoubleHexagon
      color="#E072A4"
      color2="#F7B32B"
      firstText="Allow patients to connect instantly with anyone available at your facility"
      secondText="Easily record consultation summaries from each visit"
      next={() => toggle(step3)}
      back={() => toggle(step1)}
    />
  );
  const step3 = (
    <SingleHexagon
      color="#4CC8E7"
      boldText="How does it work?"
      SideComponent={<CallTutorial />}
      next={() => toggle(step4)}
      back={() => toggle(step2)}
    />
  );
  const step4 = (
    <SingleHexagon
      color="#4CC8E7"
      boldText="How does it work?"
      SideComponent={<PatientCallTutorial />}
      next={() => toggle(step5)}
      back={() => toggle(step3)}
    />
  );
  const step5 = (
    <SingleHexagon
      color="#1A936F"
      boldText="Try it out!"
      SideComponent={<TryCalling />}
      back={() => toggle(step4)}
      finish={toggleModal}
    />
  );

  const [currentStep, setCurrentStep] = useState(step1);
  return <>{currentStep}</>;
};

export default VoipSplashScreen;
