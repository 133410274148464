import React from 'react';
import PropTypes from 'prop-types';

import TimeUtil from '../../Utils/Time';
import Avatar from '../../Blocks/Avatar';

const SingleMessageEntry = ({
  patient,
  message,
  consultationId,
  conversationId,
  onSelect,
  status,
  isActive,
}) => {
  const { firstName, lastName } = patient.name;

  const viewMessage = () => {
    onSelect({ conversationId, consultationId, patientId: patient.id });
  };

  return (
    <div
      className={
        isActive
          ? 'p-2 w-100 consultation-list-box active'
          : 'p-2 w-100 consultation-list-box'
      }
      onClick={viewMessage}
    >
      <div className={'consultation-chat-list-col1 m-auto'}>
        <Avatar letter={firstName.charAt(0)} />
      </div>

      <div className={'consultation-chat-list-col2'}>
        <div className={'chat-name font-weight-normal'}>{`${firstName} ${lastName}`}</div>
        {message && <div className={'chat-last-message'}>{message.content.message}</div>}
      </div>

      {message && (
        <div className={'consultation-chat-list-col3'}>
          {TimeUtil.displayTime(message.sent_ts)}
          <br />
          {status === 'closed' ? (
            <span> Chat Closed </span>
          ) : status === 'pending_patient_approval' ? (
            <span> Pending </span>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
};

SingleMessageEntry.defaultProps = {
  status: null,
  isActive: false,
  consultationId: null,
  conversationId: null,
};

SingleMessageEntry.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  message: PropTypes.shape({
    consultation_id: PropTypes.string,
    content: PropTypes.shape({
      message: PropTypes.string,
    }).isRequired,
    sent_ts: PropTypes.string,
  }).isRequired,
  consultationId: PropTypes.string,
  conversationId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  status: PropTypes.string,
  isActive: PropTypes.bool,
};

export default SingleMessageEntry;
