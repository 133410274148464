import axios from 'axios';

import { ADMIN_MODULE_URL } from '../Constants';

const instance = axios.create({
  baseURL: ADMIN_MODULE_URL,
  headers: { 'Content-Type': 'application/json' },
});

instance.interceptors.request.use(config => {
  config.headers.Authorization = sessionStorage.getItem('access_token');
  return config;
});

export async function pingAdminModule() {
  return await instance.get('/actuator/health');
}

export async function getOrganizationDetails(practitionerId) {
  try {
    const res = await instance.get(
      `/organization/search?practitionerId=${practitionerId}`
    );
    return res.data.data[0];
  } catch (err) {
    console.log('Error fetching organization details', err);
    // TODO: handle error
  }
}

export async function getPractitionerDetails(practitionerId) {
  try {
    const res = await instance.get(`/practitioner?id=${practitionerId}`);
    return res.data.data;
  } catch (err) {
    // TODO: handle error
  }
}

export async function getPatientDetails(patientId) {
  try {
    const res = await instance.get('/patient', {
      params: { id: patientId },
    });
    return res.data.data;
  } catch (err) {
    console.log('Error fetching patient details', err);
  }
}

export async function getPatientByPhoneNumber(phoneNumber) {
  try {
    const res = await instance.get('/patient/search', {
      params: { phoneNumber },
    });
    return res.data.data[0];
  } catch (err) {
    console.log('Error fetching patient details', err);
    return null;
  }
}

export async function getOrganizationPractitioners(organizationName) {
  try {
    const res = await instance.get(
      `/practitioner/search?organizationName=${organizationName}`
    );
    return res.data.data;
  } catch (err) {
    console.log('Error fetching organization details', err);
    // TODO: handle error
  }
}

export async function getOrganizationPatients(organizationId) {
  try {
    const res = await instance.get('/patient/search/gates', {
      params: { organizationId },
    });
    return res.data.data;
  } catch (err) {
    console.log('Error fetching organization patient', err);
    // TODO: handle error
  }
}

export async function createPatient(patientDetails) {
  try {
    const res = await instance.post('/patient/', patientDetails);
    return res.data.data;
  } catch (err) {
    console.log('Error creating patient', err);
    throw err;
  }
}

export async function fetchMCHServices(patientId) {
  try {
    const res = await instance.get('/patient/mchservices', {
      params: { patientId },
    });
    return res.data;
  } catch (err) {
    console.log(`Error fetching MCH services for ${patientId}`, err);
    throw err;
  }
}

export async function fetchPatientVisits(patientId) {
  try {
    const res = await instance.get('/patient/visits', {
      params: { patientId },
    });
    return res.data;
  } catch (err) {
    console.log(`Error fetching patient visits for ${patientId}`, err);
    throw err;
  }
}

export async function fetchA1cResults(patientId) {
  const accessToken = sessionStorage.getItem('access_token');
  try {
    const res = await instance.get('/pixotest/list/a1c', {
      params: { patientId },
      headers: { Authorization: accessToken },
    });
    return res.data;
  } catch (err) {
    console.log(`Error fetching a1c Results for ${patientId}`, err);
    throw err;
  }
}
