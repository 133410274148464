import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Tooltip } from 'reactstrap';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import history from 'history/browser';

import { AppContext } from '../../AppContext';

import {
  getLastConsultationMessage,
  getAllConsultationsForPatient,
  getPatientConversation,
} from '../../Services/backendService';
import {
  fetchMCHServices,
  fetchPatientVisits,
  fetchA1cResults,
} from '../../Services/adminModuleService';
import DiabetesTable from '../Tables/Hba1c';
import PatientSummary from '../PatientPortal/PatientSummary';
import ChatIcon from '../../Assets/chat-square.svg';
import DoctorIcon from '../../Assets/doctor.svg';
import TimeUtil from '../../Utils/Time';
import TelephoneIcon from '../../Assets/telephone.svg';

const PatientDetails = ({ activePatient, toggleDetails }) => {
  const navigate = useNavigate();

  const { showCallInterface, initiateCallRef } = useContext(AppContext);

  const [activeTab, setActiveTab] = useState('mch');
  const [orgMessage, setOrgMessage] = useState(null);
  const [consultations, setConsultations] = useState({});
  const [mostRecentConsultation, setMostRecentConsultation] = useState(null);
  const [consultationMessages, setConsultationMessages] = useState([]);
  const [mchService, setMchService] = useState(null);
  const [mostRecentVisit, setMostRecentVisit] = useState(null);
  const [hba1cReadings, setHba1cReadings] = useState(null);
  const [statusHidden, setStatusHidden] = useState(true);
  const [enrollmentHidden, setEnrollmentHidden] = useState(true);

  const activePractitioner = sessionStorage.getItem('practitioner_id');

  const fetchAllConsultations = async patientId => {
    const patientConsultations = await getAllConsultationsForPatient(patientId);

    if (patientConsultations) {
      const promises = [];
      const consultationMap = {};
      let activeConsultation = null;

      patientConsultations.forEach(consultation => {
        consultationMap[consultation._id] = consultation;
        if (['pending_patient_approval', 'active'].includes(consultation.status)) {
          activeConsultation = consultation;
        }
        promises.push(getLastConsultationMessage(consultation._id));
      });

      setMostRecentConsultation(activeConsultation);
      setConsultations(consultationMap);

      return Promise.all(promises);
    }
  };

  const fetchLastMessage = async patientId => {
    const response = await getPatientConversation(patientId);
    setOrgMessage(response.last_message);
  };

  useEffect(() => {
    const { id } = activePatient;

    fetchAllConsultations(id).then(messages => {
      setConsultationMessages(messages.map(m => m[0]));
    });

    fetchMCHServices(id).then(res => {
      if (res.result === 'OK' && res.data.length > 0) {
        setMchService(res.data[0]);
      } else {
        setMchService(null);
      }
    });

    fetchPatientVisits(id).then(res => {
      if (res.result === 'OK' && res.data.length > 0) {
        setMostRecentVisit(res.data[0]);
      } else {
        setMostRecentVisit(null);
      }
    });

    fetchA1cResults(id).then(res => {
      if (res.data.length > 0) {
        setHba1cReadings(res.data);
      } else {
        setHba1cReadings(null);
      }
    });

    fetchLastMessage(id);
  }, [activePatient]);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const goToActiveChat = () => {
    if (
      mostRecentConsultation &&
      practitionerCanViewConsultation(mostRecentConsultation._id)
    ) {
      const path = `/?consultationId=${mostRecentConsultation._id}`;
      history.push(path);
      navigate(path);
    } else {
      goToConversation();
    }
  };

  const goToConversation = () => {
    if (orgMessage) {
      const path = orgMessage.conversation_id
        ? `/?conversationId=${orgMessage.conversation_id}`
        : `/?broadcastId=${orgMessage.broadcast_id}`;
      history.push(path);
      navigate(path);
    }
  };

  const goToConsultation = consultationId => {
    if (practitionerCanViewConsultation(consultationId)) {
      const path = `/?consultationId=${consultationId}`;
      history.push(path);
      navigate(path);
    }
  };

  const practitionerCanViewConsultation = consultationId => {
    const consultation = consultations[consultationId];
    return (
      consultation &&
      consultation.consultation_accepted &&
      consultation.consultation_accepted.practitioner_id === activePractitioner
    );
  };

  const hasActiveConsultation = () => {
    return (
      mostRecentConsultation &&
      practitionerCanViewConsultation(mostRecentConsultation._id)
    );
  };

  const renderOrganizationMessages = () => {
    return (
      orgMessage && (
        <tr key={orgMessage._id} onClick={goToConversation} className="cursor-pointer">
          <td className="w-75">{orgMessage.content.message}</td>
          <td> {moment(orgMessage.sent_ts).format('DD-MM-YYYY hh:mm A')}</td>
        </tr>
      )
    );
  };

  const renderConsultationMessages = () => {
    const reversedMsgs = [...consultationMessages].reverse();
    return reversedMsgs.map(message => (
      <tr
        key={message._id}
        onClick={() => goToConsultation(message.consultation_id)}
        className="cursor-pointer"
      >
        <td className="w-75">{message.content.message.replace(/\\n/g, '\n')}</td>
        <td>{moment(message.sent_ts).format('DD-MM-YYYY hh:mm A')}</td>
      </tr>
    ));
  };

  const dateDisplay = dateObject => {
    if (!dateObject) {
      return <td></td>;
    }

    const dateString = `${dateObject.dayOfMonth}-${dateObject.month}-${dateObject.year}`;
    const date = moment(dateString, 'D-MMMM-YYYY');
    return (
      <td>
        {date.format('DD/MM/YYYY')}{' '}
        <span className="ml-2 text-muted">({TimeUtil.displayTime(date)})</span>
      </td>
    );
  };

  const toggleStatus = () => {
    setStatusHidden(!statusHidden);
  };

  const toggleEnrollment = () => {
    setEnrollmentHidden(!enrollmentHidden);
  };

  const onStartCall = () => {
    if (!showCallInterface) {
      initiateCallRef.current({ patient: activePatient });
    }
  };

  const nextOfKin = activePatient.contact[0];

  if (Object.keys(activePatient).length !== 0) {
    return (
      <>
        {isMobile && (
          <button className="my-auto button-reset pt-2" onClick={toggleDetails}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
            <span className="px-1">Back</span>
          </button>
        )}
        <div
          className={classnames(
            'px-2 pt-3 pb-4 mb-2 d-flex flex-column justify-content-start',
            {
              'text-sm': isMobile,
            }
          )}
        >
          {(hasActiveConsultation() || orgMessage) && (
            <div>
              <button
                className="btn btn-light btn-sm text-primary mx-2"
                onClick={goToActiveChat}
              >
                Go to Active Chat
              </button>
            </div>
          )}
          <PatientSummary details={activePatient} view="practitioner" />
          <hr />

          <div className="w-100 patient-details-tabs">
            <Nav className="w-100" tabs justified={true}>
              <NavItem className={classnames('py-1', { active: activeTab === 'mch' })}>
                <NavLink onClick={() => toggle('mch')}>
                  <div className="my-auto text-center">MCH - Mother Services</div>
                </NavLink>
              </NavItem>
              <NavItem
                className={classnames('py-1', { active: activeTab === 'consultations' })}
              >
                <NavLink onClick={() => toggle('consultations')}>
                  <div className="my-auto">
                    <img src={DoctorIcon} alt="consultations" className="m-1 icon-sm" />
                    <span className="ml-2">Consultations</span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem
                className={classnames('py-1', { active: activeTab === 'diabetes' })}
              >
                <NavLink style={{ height: '100%' }} onClick={() => toggle('diabetes')}>
                  <div className={isMobile ? 'mt-4' : 'my-auto'}>
                    <span className="ml-2">Diabetes</span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem
                className={classnames('py-1', { active: activeTab === 'messages' })}
              >
                <NavLink onClick={() => toggle('messages')}>
                  <div className="my-auto ">
                    <img src={ChatIcon} alt="messages" className="m-1 icon-sm" />
                    <span className="ml-2">Messages</span>
                  </div>
                </NavLink>
              </NavItem>
            </Nav>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="mch">
              {mchService ? (
                <table class="table table-borderless table-striped">
                  <tbody>
                    {/* MCH Service enrollment details */}
                    <tr>
                      <td>ANC Number</td>
                      <td>{mchService.ancNumber}</td>
                    </tr>
                    <tr>
                      <td>Enrolled</td>
                      {dateDisplay(mchService.enrollmentDate)}
                    </tr>
                    <tr>
                      <td>Gestation</td>
                      <td>{mchService.gestation} weeks</td>
                    </tr>
                    <tr>
                      <td>LMP</td>
                      {dateDisplay(mchService.lastMenstrualPeriod)}
                    </tr>
                    <tr>
                      <td>EDD (LMP)</td>
                      {dateDisplay(mchService.expectedDeliveryDate)}
                    </tr>
                    <tr>
                      <td>Gravida</td>
                      <td>{mchService.gravida}</td>
                    </tr>
                    <tr>
                      <td>Parity</td>
                      <td>{mchService.parity}</td>
                    </tr>
                    {/* Patient visit data */}
                    {mostRecentVisit && (
                      <>
                        <tr>
                          <td>Last Visit Date</td>
                          {dateDisplay(mostRecentVisit.visitDate)}
                        </tr>
                        <tr>
                          <td>Systolic BP</td>
                          <td>{mostRecentVisit.systolicBp}</td>
                        </tr>
                        <tr>
                          <td>Diastolic BP</td>
                          <td>{mostRecentVisit.diastolicBp}</td>
                        </tr>
                        <tr>
                          <td>Heart Rate</td>
                          <td>{mostRecentVisit.heartRate}</td>
                        </tr>
                        <tr>
                          <td>Respiratory Rate</td>
                          <td>{mostRecentVisit.respiratoryRate}</td>
                        </tr>
                        <tr>
                          <td>Oxygen Saturation</td>
                          <td>{mostRecentVisit.oxygenSaturation}</td>
                        </tr>
                        <tr>
                          <td>Temperature</td>
                          <td>{mostRecentVisit.temperature}</td>
                        </tr>
                        <tr>
                          <td>Neurologic Response</td>
                          <td>{mostRecentVisit.neurologicResponse}</td>
                        </tr>
                        <tr>
                          <td>Foetal Movement</td>
                          <td>{mostRecentVisit.foetalMovement}</td>
                        </tr>
                        <tr>
                          <td>HIV Status</td>
                          <td>
                            {statusHidden && (
                              <button
                                className="button-reset px-1 font-italic text-muted"
                                onClick={toggleStatus}
                              >
                                Click to show
                              </button>
                            )}
                            {!statusHidden && (
                              <>
                                <span>{mostRecentVisit.hivStatus}</span>
                                <button
                                  className="button-reset px-1 ml-2 font-italic text-muted"
                                  onClick={toggleStatus}
                                >
                                  hide
                                </button>
                              </>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Hypertensive</td>
                          <td>{mostRecentVisit.hypertensive}</td>
                        </tr>
                        <tr>
                          <td>Diabetic</td>
                          <td>{mostRecentVisit.diabetic}</td>
                        </tr>
                        <tr>
                          <td>Risk Factor</td>
                          <td>{mostRecentVisit.riskFactor}</td>
                        </tr>
                      </>
                    )}
                    <tr>
                      <td>Enrolled in HIV</td>
                      <td>
                        {mchService.enrolledInHiv !== null && (
                          <>
                            {enrollmentHidden && (
                              <button
                                className="button-reset px-1 font-italic text-muted"
                                onClick={toggleEnrollment}
                              >
                                Click to show
                              </button>
                            )}
                            {!enrollmentHidden && (
                              <>
                                <span>{mchService.enrolledInHiv ? 'Yes' : 'No'}</span>
                                <button
                                  className="button-reset px-1 ml-2 font-italic text-muted"
                                  onClick={toggleEnrollment}
                                >
                                  hide
                                </button>
                              </>
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Enrolled in TB</td>
                      <td>
                        {mchService.enrolledInTb !== null &&
                          (mchService.enrolledInTb ? 'Yes' : 'No')}
                      </td>
                    </tr>
                    <tr>
                      <td>Enrolled in IPT</td>
                      <td>
                        {mchService.enrolledInIpt !== null &&
                          (mchService.enrolledInIpt ? 'Yes' : 'No')}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <div className="text-center pt-4 text-muted">
                  Patient not enrolled in{' '}
                  <span className="font-italic">MCH - Mother Services</span>
                </div>
              )}
            </TabPane>
            <TabPane tabId="consultations">
              {consultationMessages.length > 0 ? (
                <table class="table table-borderless table-striped">
                  <thead>
                    <tr>
                      <th className="font-weight-light font-italic">
                        Recent Consultation Messages
                      </th>
                      <th className="font-weight-light font-italic">Date Sent</th>
                    </tr>
                  </thead>
                  <tbody>{renderConsultationMessages()}</tbody>
                </table>
              ) : (
                <div className="text-center pt-4 text-muted">
                  Patient has not had any consultations
                </div>
              )}
            </TabPane>
            <TabPane tabId="messages">
              {orgMessage ? (
                <table class="table table-borderless table-striped">
                  <thead>
                    <tr>
                      <th className="font-weight-light font-italic">
                        Most Recent Message
                      </th>
                      <th className="font-weight-light font-italic">Date Sent</th>
                    </tr>
                  </thead>
                  <tbody>{renderOrganizationMessages()}</tbody>
                </table>
              ) : (
                <div className="text-center pt-4 text-muted">
                  There are no messages sent to or received from the patient
                </div>
              )}
            </TabPane>
            <TabPane tabId="diabetes">
              <div className="mt-5"></div>
              <DiabetesTable view="practitioner" hba1cReadings={hba1cReadings} />
            </TabPane>
          </TabContent>
        </div>
      </>
    );
  } else {
    return (
      <div>
        <p>Please Select a Patient</p>
      </div>
    );
  }
};

PatientDetails.propTypes = {
  activePatient: PropTypes.shape({
    name: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
  }),
};

export default PatientDetails;
