import React, { useContext } from 'react';
import { Input } from 'reactstrap';
import { AppContext } from '../../AppContext';
import PropTypes from 'prop-types';

const ChatWrite = ({ isDisabled, sendMessage }) => {
  const { chatMessage, setChatMessage } = useContext(AppContext);

  const onKeyPress = event => {
    if (event.key === 'Enter' && chatMessage !== '') {
      event.preventDefault();
      sendMessage(chatMessage);
      setChatMessage('');
    } else {
      setChatMessage(event.target.value);
    }
  };

  const sendMessageBtn = e => {
    if (chatMessage !== '') {
      e.preventDefault();
      sendMessage(chatMessage);
      setChatMessage('');
    }
  };

  return (
    <div className={'consultation-chat-write'}>
      <Input
        type="textarea"
        name="text"
        id="exampleText"
        value={chatMessage}
        placeholder="Write a message..."
        onKeyPress={onKeyPress}
        onChange={e => setChatMessage(e.target.value)}
        disabled={isDisabled}
      />
      <button className="sendButton" onClick={e => sendMessageBtn(e)}>
        Send
      </button>
    </div>
  );
};

ChatWrite.defaultProps = {
  isDisabled: false,
};

ChatWrite.propTypes = {
  isDisabled: PropTypes.bool,
  sendMessage: PropTypes.func.isRequired,
};

export default ChatWrite;
