import axios from 'axios';
import { BACKEND_URL } from '../Constants';

const backendClient = axios.create({
  baseURL: BACKEND_URL,
});

backendClient.interceptors.request.use(config => {
  config.headers.Authorization = sessionStorage.getItem('access_token');
  return config;
});

export async function searchICD10(text) {
  const accessToken = sessionStorage.getItem('access_token');
  try {
    const res = await axios.get('/icd10search', {
      params: {
        q: text,
      },
      headers: {
        Authorization: accessToken,
      },
    });
    return res.data;
  } catch (error) {
    console.error('Error creating consultation', error);
    throw error;
  }
}

export async function startConsultation(consultationInfo) {
  try {
    const res = await backendClient.post('/consultations', consultationInfo);
    return res.data;
  } catch (error) {
    console.error('Error creating consultation', error);
    throw error;
  }
}

export async function requestPayment(paymentDetails) {
  const practitionerId = sessionStorage.getItem('practitioner_id');
  const { consultationId, amount, label, description } = paymentDetails;
  try {
    await backendClient.post('/payments/request', {
      consultationId,
      description,
      label,
      practitionerId,
      paymentAmount: amount,
    });
  } catch (error) {
    console.log('Error requesting payment:', error);
  }
}

export async function endConsultation(payload) {
  try {
    await backendClient.post('consultations/end', payload);
    return null;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    console.log('Error ending consultation', error);
    throw error;
  }
}

export async function restartConsultation(consultationId) {
  const practitionerId = sessionStorage.getItem('practitioner_id');
  try {
    await backendClient.post('register/restart_consultation', {
      consultationId,
      practitionerId,
    });
  } catch (error) {
    console.log('Error restarting consultation:', error);
  }
}

export async function getOpenConsultationsForPatient(patientId) {
  const organizationId = sessionStorage.getItem('organization_id');
  try {
    const res = await backendClient.get(
      `consultations/search?patientId=${patientId}&organizationId=${organizationId}&status=active&status=pending_patient_approval`
    );
    return res.data;
  } catch (error) {
    console.log(`Error fetching open consultations for ${patientId}:`, error);
  }
}

export async function getAllConsultationsForPatient(patientId) {
  const organizationId = sessionStorage.getItem('organization_id');
  try {
    const res = await backendClient.get(
      `consultations/search?patientId=${patientId}&organizationId=${organizationId}`
    );
    return res.data;
  } catch (error) {
    console.log(`Error fetching consultations for ${patientId}:`, error);
  }
}

export async function getLastConsultationMessage(consultationId) {
  try {
    const res = await backendClient.get(
      `messages/search?consultationId=${consultationId}&limit=1`
    );
    return res.data;
  } catch (error) {
    console.log('Error fetching last consultation message:', error);
  }
}

export async function getConsultationMessages(consultationId) {
  try {
    const res = await backendClient.get('messages/search/', {
      params: { consultationId },
    });
    return res.data;
  } catch (error) {
    console.log('Error fetching consultation messages:', error);
  }
}

export async function getPatientConversation(patientId) {
  try {
    const res = await backendClient.get('messages/search', {
      params: { patientId },
    });
    return res.data;
  } catch (error) {
    console.log('Error fetching conversation messages:', error);
  }
}

export async function getPaymentRequests(consultationId) {
  try {
    const res = await backendClient.get(
      `payments/requests/search?consultationId=${consultationId}`
    );
    return res.data;
  } catch (error) {
    console.log(
      `Error fetching payment requests for consultation ${consultationId}:`,
      error
    );
  }
}

export async function cancelPaymentRequest(consultationId, paymentRequestId) {
  try {
    await backendClient.post('/payments/cancel_payment_request', {
      consultationId,
      paymentRequestId,
    });
  } catch (error) {
    console.log(`Error cancelling payment request ${paymentRequestId}:`, error);
  }
}

export async function signUpConfirmation(patientDetails) {
  try {
    await backendClient.post('/register/patient_confirmation', patientDetails);
  } catch (error) {
    console.log('Error sending signup confirmation to patient:', error);
    throw error;
  }
}

export async function createVoiceClient(config) {
  try {
    const res = await backendClient.post('/voice/client', config);
    return res.data;
  } catch (error) {
    console.log('Error creating a WebRTC client:', error);
    throw error;
  }
}

export async function refreshVoiceClient(clientId) {
  try {
    const res = await backendClient.get(`/voice/client/${clientId}`);
    return res.data;
  } catch (error) {
    console.log('Error refreshing WebRTC client:', error);
    throw error;
  }
}
