import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import moment from 'moment';

import CallActionsButton from './CallsActionsButton';
import { AppContext } from '../../AppContext';
import {
  getPatientByPhoneNumber,
  pingAdminModule,
} from '../../Services/adminModuleService';
import {
  ATClientActions,
  ATClientEvent,
  CallState,
  CallType,
  createWebRTCClient,
  deviceCanReceiveAndMakeCalls,
  deviceIsRegistered,
  executeClientAction,
  getWebRTCClient,
} from '../../Services/voiceService';
import SplashScreenModal from './SplashScreenModal';
import { formatPhoneNumber } from '../../Utils/phoneNumbers';

import Avatar from '../../Blocks/Avatar';
import TelephoneIcon from '../../Assets/telephone.svg';
import TelephoneXIcon from '../../Assets/telephone-x.svg';
import MicIcon from '../../Assets/mic.svg';
import MicMuteIcon from '../../Assets/mic-mute.svg';

function VoiceCall() {
  const {
    showCallInterface,
    callMetadataRef,
    userIsAuthenticated,
    setShowCallInterface,
    initiateCallRef,
    chatMessage,
  } = useContext(AppContext);

  const {
    patient,
    phoneNumber,
    state,
    duration: _duration,
    isMuted: _isMuted,
  } = callMetadataRef.current;

  const atClientRef = useRef(null);
  const chatMessageRef = useRef('');
  const nodeRef = React.useRef(null);

  const [showPrefsModal, setShowPrefsModal] = useState(false);
  const [callState, setCallState] = useState(state);
  const [isMuted, setIsMuted] = useState(_isMuted);
  const [duration, setDuration] = useState(_duration);

  initiateCallRef.current = useCallback(
    ({ patient }) => {
      callMetadataRef.current = {
        type: CallType.OUTGOING,
        duration: 0,
        patient: patient.name,
        phoneNumber: formatPhoneNumber(patient.phoneNumber[0]),
        isMuted: false,
      };
      setCallState(CallState.DIALING);
      executeClientAction(
        atClientRef.current,
        ATClientActions.CALL,
        patient.phoneNumber[0]
      );
      setShowCallInterface(true);
    },
    [setCallState, setShowCallInterface, callMetadataRef]
  );

  useEffect(() => {
    if (callState === CallState.IN_PROGRESS) {
      const timer = setTimeout(() => {
        setDuration(duration + 1);
        callMetadataRef.current.duration = duration + 1;
      }, 1000);

      return () => clearTimeout(timer);
    }
  });

  const registerAtClientListeners = useCallback(() => {
    const client = atClientRef.current;
    client.on(ATClientEvent.READY, () => {
      console.log('AT client is ready to receive calls.');
      const audioEl = document.getElementById('remote-stream-audio');
      audioEl.loop = true;
    });

    client.on(ATClientEvent.INCOMING_CALL, params => {
      getPatientByPhoneNumber(params.from).then(patient => {
        callMetadataRef.current = {
          patient: patient.name,
          phoneNumber: formatPhoneNumber(params.from),
          type: CallType.INCOMING,
          state: CallState.RINGING,
          onHold: false,
          isMuted: false,
          duration: 0,
        };
        setCallState(CallState.RINGING);
        setShowCallInterface(true);
      });
    });

    client.on(ATClientEvent.CALLING, () => {
      callMetadataRef.current.state = CallState.DIALING;
      setCallState(CallState.DIALING);
    });

    client.on(ATClientEvent.CALL_ACCEPTED, () => {
      callMetadataRef.current.state = CallState.IN_PROGRESS;
      callMetadataRef.current.duration = 0;
      setDuration(0);
      setCallState(CallState.IN_PROGRESS);
    });

    client.on(ATClientEvent.HANG_UP, () => {
      callMetadataRef.current.state = CallState.COMPLETED;
      setCallState(CallState.COMPLETED);
      setTimeout(() => {
        setShowCallInterface(false);
      }, 2000);
    });

    client.on(ATClientEvent.OFFLINE, () => {
      console.log('AT client token has expired. Attempting to refresh.');
      reloadPage();
    });

    // The AT client library doesn't offer a means of re-establishing a socket connection for now
    // so one has to reload the page to initialize the client and establish a new connection
    client.on(ATClientEvent.CLOSED, () => {
      console.log('Connection to AT servers lost. Attempting to reconnect.');
      reloadPage();
    });

    client.on(ATClientEvent.NOT_READY, () => {
      console.log('AT client is NOT ready to receive calls');
      // TODO: Error state, determine cause of action
    });
  }, [callMetadataRef, setShowCallInterface, setCallState]);

  const reloadPage = async () => {
    const currentMessage = chatMessageRef.current;
    if (currentMessage === '') {
      const connected = await checkInternetConnection();
      if (connected) {
        window.location.reload();
      }
      console.log('No internet connection...');
    }
    setTimeout(reloadPage, 10000);
  };

  const checkInternetConnection = async () => {
    if (!window.navigator.onLine) return false;
    let connected = true;
    try {
      await pingAdminModule();
    } catch (error) {
      if (error.message === 'Network Error') {
        connected = false;
      }
    }
    return connected;
  };

  useEffect(() => {
    chatMessageRef.current = chatMessage;
  }, [chatMessage]);

  useEffect(() => {
    if (atClientRef.current) {
      console.log('Using existing AT client.');
      return;
    }

    if (deviceIsRegistered() && deviceCanReceiveAndMakeCalls()) {
      getWebRTCClient().then(client => {
        atClientRef.current = client;
        registerAtClientListeners();
      });
      return;
    }

    if (userIsAuthenticated) {
      console.log('No AT client found.');
      let enableOutgoing = true;
      let enableIncoming = true;
      createWebRTCClient({ enableIncoming, enableOutgoing }).then(() => {
        getWebRTCClient().then(client => {
          atClientRef.current = client;
          registerAtClientListeners();
        });
      });
      setShowPrefsModal(true);
    }
  }, [setShowPrefsModal, userIsAuthenticated, registerAtClientListeners]);

  const durationToHHmmss = () => {
    if (duration) return moment.utc(duration * 1000).format('HH:mm:ss');
    return '00:00:00';
  };

  const formattedCallState = () => {
    switch (callState) {
      case CallState.DIALING:
        return <p className="text-center">Dialing...</p>;
      case CallState.COMPLETED:
        return (
          <div className="mx-auto px-2">
            <p className="text-center m-0 text-body">Call ended</p>
            <p className="m-1 text-center w-100 text-muted">{durationToHHmmss()}</p>
            <p className="mt-3 mx-2 border-top text-xs text-muted text-left">
              A summary of the call will be sent to chat with the patient
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  const onMute = () => {
    callMetadataRef.current.isMuted = true;
    setIsMuted(true);
    dispatchAction(ATClientActions.MUTE);
  };

  const onUnmute = () => {
    callMetadataRef.current.isMuted = false;
    setIsMuted(false);
    dispatchAction(ATClientActions.UNMUTE);
  };

  const togglePrefsModal = () => setShowPrefsModal(prev => !prev);

  const createClient = preferences => {
    createWebRTCClient(preferences).then(() => {
      getWebRTCClient().then(client => {
        atClientRef.current = client;
        registerAtClientListeners();
      });
    });
  };

  const onPreferencesSave = preferences => {
    createClient(preferences);
  };

  const dispatchAction = action => {
    executeClientAction(atClientRef.current, action);
  };

  return (
    <>
      {showCallInterface && (
        <Draggable nodeRef={nodeRef}>
          <div
            className="shadow py-2 px-1 bg-white rounded call-interface"
            ref={nodeRef}
            style={{ position: 'absolute' }}
          >
            <div className="d-flex flex-column">
              {/* patient details */}
              <div className="d-flex flex-row justify-content-center mt-2">
                <div className="my-auto">
                  <Avatar letter={patient.firstName.charAt(0)} />
                </div>
                <h5 className="ml-2 my-auto font-weight-normal text-reset">
                  {patient.firstName} {patient.lastName}
                </h5>
              </div>

              {/* Phone number */}
              <div className="m-1 py-1 text-sm font-weight-light text-center w-100 text-muted">
                {phoneNumber}
              </div>

              {/* Status */}
              <div className="m-1 w-100 p-0 font-weight-light">
                {formattedCallState()}
              </div>

              {/* Duration */}
              {callState === CallState.IN_PROGRESS && (
                <div className="m-1 text-center w-100">{durationToHHmmss()}</div>
              )}

              {/* Actions */}
              <div className="m-1 d-flex flex-row justify-content-center w-100">
                {callState === CallState.RINGING && (
                  <>
                    <CallActionsButton
                      label="Accept"
                      icon={TelephoneIcon}
                      color="#68d391"
                      clickHandler={() => dispatchAction(ATClientActions.ANSWER)}
                    />
                    <CallActionsButton
                      label="Reject"
                      icon={TelephoneXIcon}
                      color="#fc8181"
                      clickHandler={() => dispatchAction(ATClientActions.HANG_UP)}
                    />
                  </>
                )}
                {callState === CallState.DIALING && (
                  <CallActionsButton
                    label="Cancel"
                    icon={TelephoneXIcon}
                    color="#fc8181"
                    clickHandler={() => dispatchAction(ATClientActions.HANG_UP)}
                  />
                )}
                {callState === CallState.IN_PROGRESS && (
                  <>
                    <CallActionsButton
                      label="Hang up"
                      icon={TelephoneXIcon}
                      color="#fc8181"
                      clickHandler={() => dispatchAction(ATClientActions.HANG_UP)}
                    />
                    {!isMuted && (
                      <CallActionsButton
                        label="Mute"
                        icon={MicMuteIcon}
                        clickHandler={onMute}
                      />
                    )}
                    {isMuted && (
                      <CallActionsButton
                        label="Unmute"
                        icon={MicIcon}
                        clickHandler={onUnmute}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </Draggable>
      )}
      <SplashScreenModal
        show={showPrefsModal}
        toggleModal={togglePrefsModal}
        onSubmit={onPreferencesSave}
      />
    </>
  );
}

export default VoiceCall;
