import React from 'react';
import CallBar from '../../Assets/CallBar.svg';
import CallSquare from '../../Assets/CallSquare.svg';

const CallTutorial = () => {
  return (
    <div>
      <p className="description">
        Call your patients using the call <br /> button in the chat window
      </p>
      <img className=" w-1 mr-2 callBar" src={CallBar} alt="CallBar" />
      <p className="mt-4 description">or on the patient profile page</p>
      <img className=" w-1 mr-2 CallSquare" src={CallSquare} alt="CallSquare" />
      <p className="mt-4 description">
        and your patient will receive it as a <br /> normal call.
      </p>
      <span className="footnote">
        * Patients do not need to have an internet connection for this service.
      </span>
    </div>
  );
};

export default CallTutorial;
