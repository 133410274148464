import React from 'react';
import PropTypes from 'prop-types';

const CallActionsButton = ({ icon, label, clickHandler, color }) => {
  return (
    <div className="p-2">
      <button
        className="call-action button-reset d-flex flex-column p-3 m-1 rounded-circle"
        style={{ backgroundColor: color }}
        type="button"
        onClick={clickHandler}
        onTouchStart={clickHandler}
      >
        <img className="mx-auto w-1" src={icon} alt={label} />
      </button>
      <p className="text-center text-xs pt-1">{label}</p>
    </div>
  );
};

CallActionsButton.defaultProps = {
  color: '#cbd5e0', // gray
};

CallActionsButton.propTypes = {
  icon: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  color: PropTypes.string,
};

export default CallActionsButton;
