import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function FormSelect({
  label,
  name,
  icon,
  onValueChange,
  validator,
  isRequired,
  options,
}) {
  const [inputValue, setInputValue] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [isValid, setIsValid] = useState(!isRequired);
  const [hasError, setHasError] = useState(false);
  const [showCheckmark, setShowCheckmark] = useState(false);

  const updateValue = evt => {
    const value = evt.target.value;
    const valid = validator
      ? validator(value) && evt.target.validity.valid
      : evt.target.validity.valid;
    onValueChange({ name, value, valid });
    setInputValue(value);
    setIsValid(valid);
    setShowCheckmark(valid);
  };

  const onBlur = () => {
    setIsActive(false);
    if (!isValid) {
      setIsValid(false);
      setHasError(true);
    }
  };

  const onFocus = () => {
    const valid = validator ? validator(inputValue) && isValid : isValid;
    setIsActive(true);
    setIsValid(valid);
    setHasError(false);
  };

  return (
    <div className="form-field">
      <img className="form-field__leading" src={icon} alt={label} />
      <div
        className={classnames('form-field__control', {
          'form-field--is-active': isActive,
          'form-field--is-filled': !!inputValue,
        })}
      >
        <label htmlFor={name} className="form-field__label">
          {label}
        </label>
        <select
          id={name}
          className={classnames('form-field__select', {
            'form-field__input_error': hasError,
          })}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={updateValue}
          value={inputValue}
        >
          <option value="" disabled selected></option>
          {options.map(option => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
        {showCheckmark && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="form-field__ending"
            viewBox="0 0 16 16"
          >
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg>
        )}
      </div>
    </div>
  );
}

FormSelect.defaultProps = {
  type: 'text',
  isRequired: true,
};

FormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  onValueChange: PropTypes.func.isRequired,
  validator: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
};

export default FormSelect;
