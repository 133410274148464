import React from 'react';
import { Button } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import Hexagon from './Hexagon';
import logo from '../../Assets/ilara-white-icon.svg';

const SingleHexagon = ({
  color,
  boldText,
  SideComponent = null,
  name,
  next,
  back,
  finish,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const organizationName = sessionStorage.getItem('organization_name');

  return (
    <div className="gradient">
      <div className="d-flex justify-content-center">
        <div className="mt-2">
          <img className=" w-1 mr-2 logo" src={logo} alt="logo" />
          <span className="medical">{organizationName}</span>
        </div>
      </div>
      <div
        className={
          isMobile
            ? 'd-flex flex-column justify-content-around hex-spacing'
            : 'd-flex justify-content-between hex-spacing'
        }
      >
        <Hexagon color={color} boldText={boldText} name={name} />
        <div
          className={classnames('d-flex flex-column mt-5', {
            sideComponentTextContainer: SideComponent,
            sideComponentButton: !SideComponent,
          })}
        >
          {SideComponent}
          <div
            className={
              isMobile
                ? 'd-flex flex-column button-margin align-items-center'
                : 'd-flex flex-row button-margin justify-content-end '
            }
          >
            {back ? (
              <Button
                className={isMobile ? 'backButton mb-4' : 'backButton'}
                onClick={back}
              >
                Back
              </Button>
            ) : (
              ''
            )}
            {next ? (
              <Button className="nextButton" onClick={next}>
                Next
              </Button>
            ) : (
              ''
            )}
            {finish ? (
              <Button className="finishButton" onClick={finish}>
                Finish
              </Button>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleHexagon;
