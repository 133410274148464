import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

function StartConsultationModal({ isOpen, patientName, onConfirm, toggle }) {
  const [notes, setNotes] = useState('');
  const [hasNotesError, setHasNotesError] = useState(false);

  const onDescriptionChange = evt => {
    if (hasNotesError) {
      setHasNotesError(false);
    }
    setNotes(evt.target.value);
  };

  const startConsultation = () => {
    if (!notes) {
      setHasNotesError(true);
    } else {
      onConfirm(notes);
      setNotes('');
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Start Consultation with "{patientName}"?</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="consultation-notes">Consultation Notes</Label>
            <Input
              className={hasNotesError && 'validation-error-input'}
              type="textarea"
              name="consultation-notes"
              id="consultation-notes"
              placeholder="Describe what this consultation is about"
              value={notes}
              onChange={onDescriptionChange}
            />
            {hasNotesError && (
              <p className="validation-error-text">Consultation notes is required.</p>
            )}
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={startConsultation}>
          {' '}
          Start Consultation{' '}
        </Button>{' '}
        <Button color="secondary" onClick={toggle}>
          {' '}
          Cancel{' '}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

StartConsultationModal.defaultProps = {
  isOpen: false,
};

StartConsultationModal.propTypes = {
  isOpen: PropTypes.bool,
  patientName: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default StartConsultationModal;
