import React from 'react';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import PrivateRoute from './Navigation/PrivateRoutes';
import PublicRoute from './Navigation/PublicRoutes';
import { PrivateRoutes, PublicRoutes } from './routes';

import './App.scss';

const AppRoutes = () => {
  return (
    <Router>
      <React.Fragment>
        <Routes>
          {PrivateRoutes.map((route, i) => {
            return <PrivateRoute key={i} {...route} />;
          })}
          {PublicRoutes.map((route, i) => {
            return <PublicRoute key={i} {...route} />;
          })}
        </Routes>
      </React.Fragment>
    </Router>
  );
};

export default AppRoutes;
