import React, { useState, useEffect } from 'react';
import Layout from '../Blocks/Layout';
import PatientList from '../Compositions/Patients/PatientList';
import { getOrganizationPatients } from '../Services/adminModuleService';

import { sortPatientsByNames } from '../Utils';

const Patients = () => {
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    const organizationId = sessionStorage.getItem('organization_id');

    getOrganizationPatients(organizationId)
      .then(res => {
        setPatients((res || []).sort(sortPatientsByNames));
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <Layout>
      <PatientList patients={patients} />;
    </Layout>
  );
};

export default Patients;
