import axios from 'axios';
import { VAPID_PUBLIC_KEY } from './Constants';

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const vapidPublicKey = VAPID_PUBLIC_KEY;
const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

function subscribeUserToPush() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        if (!registration.pushManager) {
          console.log('Push Manager unavailable');
          return;
        }
        registration.pushManager.getSubscription().then(existingSubsription => {
          const subscribeOptions = {
            userVisibleOnly: true,
            applicationServerKey: convertedVapidKey,
          };
          registration.pushManager
            .subscribe(subscribeOptions)
            .then(newSubscription => {
              sendSubscriptionToBackend(newSubscription);
            })
            .catch(error => {
              if (Notification.permission !== 'granted') {
                console.log('Permission was not granted');
              } else {
                console.error('Error occured during the subscription process.', error);
              }
            });
        });
      })
      .catch(error => {
        console.error('An error occured during service worker registration.', error);
      });
  }
}

function sendSubscriptionToBackend(subscription) {
  const subscriptionString = JSON.stringify(subscription);
  setTimeout(() => {
    axios
      .post('/subscription/subscribe', {
        subscriptionString,
        practitioner_id: sessionStorage.getItem('practitioner_id'),
        organization_id: sessionStorage.getItem('organization_id'),
      })
      .then(response => {
        return response.json;
      })
      .catch(error => {
        console.log(error);
      });
  }, 5000);
}

// eslint-disable-next-line no-restricted-globals
self.onpushsubscriptionchange = event => {
  event.waitUntil(
    // eslint-disable-next-line no-restricted-globals
    self.registration.pushManager
      .subscribe(event.oldSubscription.options)
      .then(subscription => {
        sendSubscriptionToBackend(subscription);
      })
  );
};

export { subscribeUserToPush };
