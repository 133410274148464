import React, { useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, Checkbox } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import PhoneInput from '../../Blocks/PhoneInput';
const { Option } = Select;

const BasicInfoForm = props => {
  const [form] = Form.useForm();

  useEffect(() => {
    const { firstName, lastname, gender, dateofbirth, phone } = props;
    form.setFieldsValue({
      firstName: firstName,
      lastname: lastname,
      gender: gender,
      dateofbirth: dateofbirth,
      phone: phone,
    });
  }, []);

  const validateInput = values => {
    props.setBasicValues(values);
    props.handleNext();
  };

  const checkPhoneNumber = (rule, value) => {
    if (!value) {
      return Promise.reject('Please Input phone number');
    }
    if (!value.phone) {
      return Promise.reject('Please Input valid phone number');
    }
    if (value.phone.length < 9) {
      return Promise.reject('Please Input valid phone number');
    }
    if (!value.phoneCode || !value.code) {
      return Promise.reject('Please select valid country code');
    }
    return Promise.resolve();
  };

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
  };

  const checkBox = {
    wrapperCol: {
      span: 16,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };
  return (
    <>
      <Form
        {...layout}
        layout={'horizontal'}
        size={'default'}
        onFinish={validateInput}
        form={form}
      >
        <Form.Item
          label="First Name"
          name="firstName"
          tooltip={{
            title: 'if you would prefer to stay anonymous, you can respond with "Anon"',
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: 'Please input your First Name!',
            },
          ]}
        >
          <Input placeholder="e.g John" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastname"
          tooltip={{
            title: 'if you would prefer to stay anonymous, you can respond with "Anon"',
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: 'Please input your Last Name!',
            },
          ]}
        >
          <Input placeholder="e.g Doe" />
        </Form.Item>
        <Form.Item
          label="Gender"
          name="gender"
          rules={[
            {
              required: true,
              message: 'Please input your Gender!',
            },
          ]}
        >
          <Select placeholder="Select your Gender">
            <Option value="MALE">Male</Option>
            <Option value="FEMALE">Female</Option>
            <Option value="OTHER">Prefer not to say</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Date of Birth"
          name="dateofbirth"
          rules={[
            {
              required: true,
              message: 'Please input your Date of Birth!',
            },
          ]}
        >
          <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone Number"
          rules={[
            {
              validator: checkPhoneNumber,
            },
          ]}
        >
          <PhoneInput placeholder="input your phone number" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          {...checkBox}
          name="terms"
          className="checkbox"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject('Please accept the agreement'),
            },
          ]}
        >
          <Checkbox>
            I hereby agree and consent to the{' '}
            <a href="/privacy" target="_blank">
              {' '}
              Privacy Policy
            </a>
          </Checkbox>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default BasicInfoForm;
