import React, { useState, useEffect } from 'react';
import BasicInfoForm from '../Compositions/PatientSignUp/BasicInfoForm';
import LocationForm from '../Compositions/PatientSignUp/LocationForm';
import ConsultationMessage from '../Compositions/PatientSignUp/ConsultationMessage';
import Layout from '../Blocks/Layout';
import moment from 'moment';
import axios from 'axios';
import parsePhoneNumber from 'libphonenumber-js';
import { Spinner } from 'reactstrap';

import { Row, Col, Card, Steps } from 'antd';

function ChromeSignUp({ keycloak }) {
  const { Step } = Steps;

  const date = moment().subtract(25, 'years').format('DD/MM/YYYY');
  const defaultDate = moment(date, 'DD/MM/YYYY');

  const [current, setCurrent] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [basicInfoValues, setBasicInfoValues] = useState({
    firstName: '',
    lastname: '',
    gender: null,
    dateofbirth: defaultDate,
    phone: '',
  });
  const [locationValues, setLocationValues] = useState({
    option: null,
    coordinates: '',
  });
  const [messages, setMessages] = useState({
    organization: '',
    message: '',
  });

  const [organizations, setOrganizations] = useState([]);

  const fetchClinics = async () => {
    try {
      const data = await axios.get('register/organizations');
      setOrganizations(data.data);
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    fetchClinics();
  }, []);

  useEffect(() => {
    savePatient();
  }, [messages]);

  const validateNumber = value => {
    let newNumber = value.phoneCode + value.phone;
    const phoneNumber = parsePhoneNumber(newNumber, value.code);
    return phoneNumber;
  };

  const savePatient = async () => {
    setShowLoader(true);
    try {
      const validPhone = validateNumber(basicInfoValues.phone);
      const data = await axios.post('register/patient', {
        firstName: basicInfoValues.firstName,
        lastName: basicInfoValues.lastname,
        phoneNumber: validPhone.number,
        latitude: locationValues.coordinates.lat,
        longitude: locationValues.coordinates.lng,
        gender: basicInfoValues.gender,
        birthDate: moment(basicInfoValues.dateofbirth).format('DD/MM/YYYY'),
        message: messages.message,
        organizationId: messages.organization,
      });
      const redirectLink = data.data.redirect_link;
      window.location.replace(redirectLink);
      setShowLoader(false);
    } catch (e) {
      setShowLoader(false);
    }
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const handleSubmit = e => {
    e.preventDefault();
    console.log('event', e);
  };

  const steps = [
    {
      title: 'Basic Information',
      content: (
        <BasicInfoForm
          handleNext={next}
          {...basicInfoValues}
          setBasicValues={setBasicInfoValues}
        />
      ),
    },
    {
      title: 'Select Location',
      content: (
        <LocationForm
          handleNext={next}
          handleBack={prev}
          {...locationValues}
          setLocationValues={setLocationValues}
        />
      ),
    },
    {
      title: 'Select Message',
      content: (
        <ConsultationMessage
          organizations={organizations}
          handleSubmit={handleSubmit}
          handleBack={prev}
          {...messages}
          setMessages={setMessages}
          savePatient={savePatient}
        />
      ),
    },
  ];

  return (
    <Layout keycloak={keycloak}>
      {showLoader ? (
        <div className="spinner-div">
          <Spinner
            className="loading-spinner"
            style={{ width: '3rem', height: '3rem' }}
          />
        </div>
      ) : (
        <div className="form-layout">
          <h3 className="patient-heading">Patient Sign Up</h3>
          <hr />
          <Row type="flex" justify="center" align="middle">
            <Col>
              <Card>
                <>
                  <Steps current={current}>
                    {steps.map(item => (
                      <Step key={item.title} title={item.title} />
                    ))}
                  </Steps>
                  <div className="steps-content">{steps[current].content}</div>
                </>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </Layout>
  );
}

export default ChromeSignUp;
