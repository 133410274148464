import './Assets/Fonts/Poppins/Poppins-Black.ttf';
import './Assets/Fonts/Poppins/Poppins-BlackItalic.ttf';
import './Assets/Fonts/Poppins/Poppins-Bold.ttf';
import './Assets/Fonts/Poppins/Poppins-BoldItalic.ttf';
import './Assets/Fonts/Poppins/Poppins-ExtraBold.ttf';
import './Assets/Fonts/Poppins/Poppins-ExtraLight.ttf';
import './Assets/Fonts/Poppins/Poppins-ExtraLightItalic.ttf';
import './Assets/Fonts/Poppins/Poppins-Italic.ttf';
import './Assets/Fonts/Poppins/Poppins-Light.ttf';
import './Assets/Fonts/Poppins/Poppins-LightItalic.ttf';
import './Assets/Fonts/Poppins/Poppins-Medium.ttf';
import './Assets/Fonts/Poppins/Poppins-MediumItalic.ttf';
import './Assets/Fonts/Poppins/Poppins-Regular.ttf';
import './Assets/Fonts/Poppins/Poppins-SemiBold.ttf';
import './Assets/Fonts/Poppins/Poppins-SemiBoldItalic.ttf';
import './Assets/Fonts/Poppins/Poppins-Thin.ttf';
import './Assets/Fonts/Poppins/Poppins-ThinItalic.ttf';

import React from 'react';
import ReactDOM from 'react-dom';
import AppRoutes from './AppRoutes';
import * as serviceWorker from './serviceWorker';
import KeycloakService from './Services/KeycloakService';
import { BACKEND_URL } from './Constants';
import axios from 'axios';
import { ContextProvider } from './AppContext';

axios.defaults.baseURL = BACKEND_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const renderApp = () =>
  ReactDOM.render(
    <React.StrictMode>
      <ContextProvider>
        <AppRoutes />
      </ContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', event => {
        if (event.target.state === 'activated') {
          console.log('Update: Reload page...');
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      console.log('Update: New content Updated.');
    }
  },
});
