import React from 'react';
import Layout from '../Blocks/Layout';

function PrivacyPolicy({ keycloak }) {
  return (
    <Layout keycloak={keycloak}>
      <div className="policy-container">
        <h3>Ilara Health, Inc. Software Systems Privacy Policy</h3>
        <h5>Effective Date: April 7, 2020​​</h5>
        <p>
          At Ilara Health Inc. (Ilara), we are strongly committed to protecting your
          privacy. To provide you with the best possible health services, we might have to
          ask you to provide us and/or your Health Care Provider (HCP) with personal
          information about yourself and your personal health data. We use this
          information to improve our internal services. Your HCP uses it to provide its
          services in accordance with its privacy policies. We only use and disclose your
          personal information in accordance with our privacy policy. Additionally, we use
          industry-leading technologies to ensure the security and confidentiality of the
          personal information you provide us. Throughout this policy document, we refer
          to information that can identify you, your company/business/practice, your
          beneficiaries as a specific individual, such as your name, phone number, email
          address, national identification number or credit card number, as "personal
          information".{' '}
        </p>
        <p>
          By using or accessing the Services in any manner, regardless of whether you
          register or create an Account through the Services, you acknowledge that you
          accept the practices and policies outlined in this Privacy Policy, and you
          hereby consent that we will collect, use, and share your information in the
          following ways.
        </p>
        <h3>What does this Privacy Policy cover?</h3>
        <p>
          This Privacy Policy covers our treatment of personally identifiable information
          (“Personal Information”) that we gather when you are accessing or using our
          Services, and to the treatment of personally identifiable information by our
          partners, but not to the practices of other companies we don’t own or control,
          or people that we don’t manage. We gather various types of Personal Information
          from our users, as explained in more detail below, and we use this Personal
          Information in connection with our Services. In certain cases, we may also share
          some Personal Information with third parties, but only as described below. This
          privacy policy does not apply to anonymous/de-identified data. We may share,
          process and distribute de-identified (anonymized) health data without
          restriction.
        </p>
        <h3>How do we collect and use Personal Information?</h3>
        <p>
          We receive and store any you knowingly provide to us or to a partner HCP through
          tools and services provided by Ilara Health. We do not store or collect any
          personally identifiable information collected through tools provided by a third
          party, unless by the patient and HCP’s consent. If you have provided your
          contact information to us or a partner HCP, we may store and use that
          information to contact you for marketing and promotional purposes, as well as
          for health programs, by various means, including regular mail, email, telephone,
          including voicemail, or SMS (text message).
        </p>
        <h3>Other information; “cookies” and “tags”?</h3>
        <p>
          You should also be aware that when you use our Services, we collect certain
          “usage data,” such as the number of visitors we receive or what pages are
          visited most often. This data helps us to analyze and improve the usefulness of
          the information of our Services.
        </p>
        <p>
          We may also collect, or receive from third parties, information based on your IP
          address that provides us your geolocation data in order to identify relevant
          markets for users down to a zip code level of detail and to provide a better
          mobile experience. We do not store, sell, disclose or use this data to serve
          advertisements.
        </p>
        <p>
          Like most commercial website owners, we may use what is known as “cookie”
          technology. A “cookie” is an element of data that a website can send to your
          browser when you link to that website. It is not a computer program and has no
          ability to read data residing on your computer or instruct it to perform any
          step or function. By assigning a unique data element to each visitor, the
          website is able to recognize repeat users, track usage patterns and better serve
          you when you return to that site. Our cookies do not extract other personal
          information about you, such as your name or address. You may be able to change
          the preferences on your browser or device to prevent or limit your device’s
          acceptance of cookies, but this may prevent you from taking advantage of some of
          our features. We may also use what is known as “client-side page tagging,” which
          uses code on each page to write certain information about the page and the
          visitor to a log when a page is rendered by your web browser. This technique is
          also commonly used on commercial websites. “Tagging” does result in a JavaScript
          program running on your computer, but it is limited to providing information
          about the page that you are requesting and the configuration of your browser. It
          will not read any of your data files, or execute any additional programs. It
          does not extract any personal information about you, such as your name or
          address. You can prevent tagging by disabling JavaScript in your browser, but
          that may prevent you from using all of our Site’s functions.
        </p>
        <h3>How do we share Personal Information?</h3>
        <p>
          We do not rent or sell your Personal Information in personally identifiable form
          to anyone. We may share your Personal Information with third parties as
          described in this section.
        </p>
        <p>
          Ilara will not sell, license, transmit or disclose outside of Ilara the
          information you provide to us unless (a) expressly authorized by you, (b)
          necessary to enable our Business Associates to perform certain functions for us,
          or (c) required or permitted by law. In all cases, we will disclose the
          information consistent with applicable laws and regulations and we will require
          the recipient to protect the information and use it only for the purpose it was
          provided and as necessary to assist us. Ilara takes the Health Insurance
          Portability and Accountability Act of 1996 (HIPAA) seriously and provides
          appropriate safeguards to your personal health information (PHI) –this includes
          your name, address, national ID number, e-mail address and telephone number.
        </p>
        <p>
          We may de-identify your Personal Information so that you are not identified as
          an individual, and provide that information to our partners. We may also provide
          aggregate usage information to our partners (or allow partners to collect that
          information from you), who may use such information to understand how often and
          in what ways people use our Services, so that they, too, can provide you with an
          optimal online experience. However, we never disclose aggregate usage or
          de-identified information to a partner (or allow a partner to collect such
          information) in a manner that would identify you as an individual person.
        </p>
        <p>
          We may choose to buy or sell assets, and may share and/or transfer customer
          information in connection with the evaluation of and entry into such
          transactions. Also, if we (or our assets) are acquired, or if we go out of
          business, enter bankruptcy, or go through some other change of control, Personal
          Information could be one of the assets transferred to or acquired by a third
          party.
        </p>
        <p>
          We will retain your information for as long as your Account is active or as
          needed to provide you with services. Notwithstanding any provision to the
          contrary, we will retain, access, use and disclose your information as we
          believe is necessary to comply with our legal obligations, resolve disputes,
          enforce our Terms of Service and other agreements, or to protect the rights,
          property or safety of Ilara, our employees, our users or others.
        </p>
        ​<h3>Your email?</h3>
        <p>
          We welcome your comments or questions about our Site and Services. You can email
          your comments to our customer service center{' '}
          <a href="mailto: info@ilarahealth.com">info@ilarahealth.com</a>. We will share
          your comments and questions with our customer service representatives and those
          employees most capable of addressing your questions and concerns. Please note
          that your email, like all non-encrypted Internet email communications, may be
          accessed and viewed by other Internet users, without your knowledge and
          permission, while in transit to us. For that reason, to protect your privacy,
          please do not use email to communicate information to us that you consider
          confidential. If you wish, you may contact us instead by telephone at{' '}
          <a href="tel:+254 700 007142">+254 700 007142</a>
        </p>
        <h3>Linking to other sites?</h3>
        <p>
          From time to time, Ilara may provide links to other websites that we think might
          be useful or interesting – these are not owned or controlled by Ilara and may be
          subject to separate terms and conditions and privacy policies. While we try to
          be proactive and ensure that appropriate protections are in place, we cannot be
          responsible for the privacy practices used by other website owners or the
          content or accuracy of those other websites. Links to various non-Ilara websites
          do not constitute or imply endorsement by Ilara of these websites, any products
          or services described on these sites, or of any other material contained in
          them.
        </p>
        <h3>Security</h3>
        <p>
          Ilara has adopted and adheres to stringent security standards designed to
          protect non-public personal information at ilarahealth.com and through all of
          our software products and tools, against accidental or unauthorized access or
          disclosure. Among the safeguards that Ilara has developed for this Site are
          administrative, physical and technical barriers that together form a protective
          firewall around the information stored at this Site. We endeavor to protect the
          privacy of your Account and other Personal Information we hold in our records,
          but unfortunately, we cannot guarantee complete security. Unauthorized entry or
          use, hardware or software failure, and other factors, may compromise the
          security of user information at any time.​
        </p>
        <h3>What Personal Information can I access?</h3>
        <p>
          In some cases, registered users will be able to change their password and update
          the information that they provide to us, such as address, contact information
          and health information, by going to the settings page of their Account.
          Registered and unregistered users can access and delete cookies through their
          web browser settings. The information you can view, update, and delete may
          change as the Services change.
        </p>
        <p>
          In accordance with the Kenya Data Protection Act, any user can request that any
          of their information, including copies, be deleted permanently from our
          databases. If you have any questions about viewing or updating information we
          have on file about you, please contact us{' '}
          <a href="mailto: info@ilarahealth.com">info@ilarahealth.com</a>
        </p>
        <h3>Changes to this Privacy Policy?</h3>
        <p>
          Ilara may change this Privacy Policy from time to time; when material updates
          are made, the Privacy Policy version date will also be updated to reflect that a
          revision occurred, and we will alert you to changes by placing a notice on
          ilarahealth.com, by sending you an email, and/or by some other means. We
          encourage you to periodically reread this Privacy Policy to see if there have
          been any changes that may affect you. A user is bound by any changes to the
          Privacy Policy when he or she uses the Site or Services after such changes have
          been first posted. This Privacy Policy is not intended to and does not create
          any contractual or other legal rights in or on behalf of any party.​
        </p>
        <h3>Contact Us</h3>
        <p>
          Call us at <a href="tel:+254 700 007142">+254 700 007142</a>
        </p>
      </div>
    </Layout>
  );
}

export default PrivacyPolicy;
