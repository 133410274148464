import React from 'react';
import { Button } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';

import Hexagon from './Hexagon';
import logo from '../../Assets/ilara-white-icon.svg';

const DoubleHexagon = ({ color, color2, firstText, secondText, next, back }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const organizationName = sessionStorage.getItem('organization_name');

  return (
    <div className="gradient d-flex flex-column justify-content-between">
      <div className="d-flex justify-content-center">
        <div className="mt-2">
          <img className=" w-1 mr-2 logo" src={logo} alt="logo" />
          <span className=" medical">{organizationName}</span>
        </div>
      </div>
      <div>
        <div className="double-hexagon-left">
          <Hexagon color={color} lightText={firstText} name="hex-splash-small" />
        </div>
        <div className="double-hexagon-right">
          <Hexagon
            color={color2}
            lightText={secondText}
            name="hex-splash-small"
            leftText={true}
          />
        </div>
      </div>

      <div
        className={
          isMobile
            ? 'd-flex flex-column align-items-center'
            : 'd-flex flex-row justify-content-end'
        }
        style={{ marginBottom: '5vh' }}
      >
        {back ? (
          <Button className={isMobile ? 'backButton mb-4' : 'backButton'} onClick={back}>
            Back
          </Button>
        ) : (
          ''
        )}
        {next ? (
          <Button className="nextButton" onClick={next}>
            Next
          </Button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default DoubleHexagon;
