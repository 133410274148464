import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody } from 'reactstrap';
import VoipSplashScreen from '../../Views/VoipSplashScreen';

const SplashScreenModal = ({ show, toggleModal, onSubmit }) => {
  return (
    <Modal
      isOpen={show}
      backdrop="static"
      toggleModal={toggleModal}
      scrollable={true}
      size="lg-fullscreen"
      className="lg-fullscreen"
    >
      <ModalBody className="splash-modal">
        <Button className="close-button" close onClick={toggleModal} />
        <VoipSplashScreen onSubmit={onSubmit} toggleModal={toggleModal} />
      </ModalBody>
    </Modal>
  );
};

SplashScreenModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  testSplashscreen: PropTypes.func.isRequired,
};

export default SplashScreenModal;
