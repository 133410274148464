import React, { useState, useEffect, useContext } from 'react';
import Layout from '../Blocks/Layout';
import PatientSummary from '../Compositions/PatientPortal/PatientSummary';
import { Spinner } from 'reactstrap';
import DiabetesTable from '../Compositions/Tables/Hba1c';
import { getPatientDetails, fetchA1cResults } from '../Services/adminModuleService';
import { AppContext } from '../AppContext';

const PatientPortal = () => {
  const [patient, setPatient] = useState([]);
  const [hba1cReadings, setHba1cReadings] = useState(null);
  const { keycloak } = useContext(AppContext);

  useEffect(() => {
    const patientId = sessionStorage.getItem('patient_id');
    getPatientDetails(patientId).then(patient => {
      setPatient(patient);
    });
    fetchA1cResults(patientId).then(res => {
      if (res.data.length > 0) {
        setHba1cReadings(res.data);
      } else {
        setHba1cReadings(null);
      }
    });
  }, []);

  return (
    <Layout>
      {patient.length === 0 ? (
        <div className="spinner-div">
          <Spinner
            className="loading-spinner"
            style={{ width: '3rem', height: '3rem' }}
          />
        </div>
      ) : (
        <div>
          <PatientSummary details={patient} view="patient" />
          <DiabetesTable view="patient" hba1cReadings={hba1cReadings} />
        </div>
      )}
    </Layout>
  );
};

export default PatientPortal;
