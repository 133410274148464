import moment from 'moment';

const TimeUtil = {
  displayTime: timestamp => {
    let currDate = moment(Date.now());
    let time = moment(timestamp);
    let result = currDate.diff(time, 'seconds');

    switch (result) {
      case result > 604800:
        return time.format('L');
      case result >= 86400 && result <= 604800:
        return time.startOf('day').fromNow();
      case result >= 3600 && result < 86400:
        return time.startOf('hour').fromNow();
      case result <= 3600 && result >= 3540:
        return time.startOf('minute').fromNow();
      default:
        return time.startOf('second').fromNow();
      // code block
    }
  },
};

export default TimeUtil;
