import React from 'react';
import { useMediaQuery } from 'react-responsive';
import CallInterface from '../../Assets/CallInterface.svg';

const PatientCallTutorial = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <>
      {isMobile ? (
        <p className="description">
          Patients can in turn call our toll free line <b>+254800721092</b> and their
          calls will be automatically sent to devices at your facility
        </p>
      ) : (
        <p className="description">
          Patients can in turn call our toll <br />
          free line <b>+254800721092</b> and their calls <br />
          will be automatically sent to <br />
          devices at your facility
        </p>
      )}

      <img className=" w-1 mr-2 callInterface" src={CallInterface} alt="CallInterface" />
      <span className="footnote">
        Your facility however does require an internet connection for this service.
      </span>
    </>
  );
};

export default PatientCallTutorial;
