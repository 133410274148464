import React from 'react';
import { Button, Form, Input, Radio, Select, Space } from 'antd';

const ConsultationMessage = props => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;

  const radioStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  };

  const handleBack = () => {
    props.handleBack();
  };

  const validateInput = async values => {
    try {
      props.setMessages(values);
    } catch (e) {
      console.log('error ->', e);
    }
  };

  return (
    <>
      <Form
        style={{ padding: '1rem' }}
        layout={'vertical'}
        form={form}
        onFinish={validateInput}
      >
        <Form.Item
          label="Select Clinic"
          name="organization"
          rules={[
            {
              required: true,
              message: 'Please Select a clinic',
            },
          ]}
        >
          <Select placeholder="Select your Clinic">
            {props.organizations.map(organization => (
              <Option value={organization.id}>{organization.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Please tell us your your reason for reaching out:"
          name="reason"
          rules={[
            {
              required: true,
              message: 'Please Select your reason for reaching out.',
            },
          ]}
        >
          <Radio.Group style={radioStyle}>
            <Radio value={1}>Medical questions or to receive a consultation</Radio>
            <Radio value={2}>
              Questions about services, availbility of medication, or any other
              non-medical question
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Please describe your symptoms, ask your question(s) or enquire about services here:"
          name="message"
          rules={[
            {
              required: true,
              message: 'Please Select your reason for reaching out.',
            },
          ]}
        >
          <TextArea placeholder="e.g Hello. I'm having a headache" />
        </Form.Item>
        <Form.Item>
          <Space size={'large'}>
            <Button type="primary" htmlType="submit" onClick={() => handleBack()}>
              Back
            </Button>

            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default ConsultationMessage;
