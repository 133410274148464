import React, { useState, createContext, useRef } from 'react';

export const AppContext = createContext({
  userIsAuthenticated: false,
  showCallInterface: false,
  chatMessage: '',
  logoutRef: null,
  callMetadataRef: {},
  initiateCallRef: null,
  setUserIsAuthenticated: () => {},
  setShowCallInterface: () => {},
  setChatMessage: () => {},
});

export const ContextConsumer = AppContext.Consumer;

export const ContextProvider = ({ children }) => {
  const [userIsAuthenticated, setUserIsAuthenticated] = useState(false);
  const [showCallInterface, setShowCallInterface] = useState(false);
  const [chatMessage, setChatMessage] = useState('');
  const logoutRef = useRef(null);
  const callMetadataRef = useRef({});
  const initiateCallRef = useRef(null);

  const contextValue = {
    userIsAuthenticated,
    showCallInterface,
    chatMessage,
    callMetadataRef,
    logoutRef,
    initiateCallRef,
    setShowCallInterface,
    setUserIsAuthenticated,
    setChatMessage,
  };

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};
